import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {
    decimalPipe = new DecimalPipe('de');
    absStr: string;

    transform(data: number, _args?: any): any {
        if (isNaN(data)) { return null; }
        if (data === null) { return null; }
        if (data === 0) { return null; }
        let abs = Math.abs(data);
        const rounder = Math.pow(10, 2);
        const isNegative = data < 0;
        let key = '';
        let pre = 2;

        const config = [
            {key: 'Q', value: Math.pow(10, 15), pre: 2},
            {key: 'T', value: Math.pow(10, 12), pre: 2},
            {key: ' Mrd', value: Math.pow(10, 9), pre: 2},
            {key: ' Mio', value: Math.pow(10, 6), pre: 2},
            {key: ' Tsd', value: 1000, pre: 0}
        ];

        for (const c of config) {
            let reduced = abs / c.value;
            reduced = Math.round(reduced * rounder) / rounder;
            if (reduced >= 1) {
                abs = reduced;
                key = c.key;
                pre = c.pre;
                break;
            }
        }

        this.absStr = this.decimalPipe.transform(
            abs,
            `1.${pre}-${pre}`
          );
        return (isNegative ? '-' : '') + this.absStr + key;
    }
}
