import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClickedConfig } from 'src/app/app.config';
import { OriginalOrder } from 'src/app/helper/helper';
import { MediaQueryService } from 'src/app/services/mediaquery-service/mediaquery-service.service';
import { MainSearchData, MainSearchDataExtendedData, MainSearchDataExtendedDataDetail } from '../search.config';
import { SearchService } from '../search.service';
import { MainSearchDropdownConfig, MainSearchDropdownSearchCategories } from './main-search-dropdown.config';

@Component({
  selector: 'app-main-search-dropdown',
  templateUrl: './main-search-dropdown.component.html',
  styleUrls: ['./main-search-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainSearchDropdownComponent implements OnInit, OnDestroy {
  @Output() changed: EventEmitter<ClickedConfig> = new EventEmitter();
  data: any;
  configTiles = MainSearchDropdownConfig;
  extended: MainSearchDataExtendedData[];
  subscriptionStore: Subscription;
  active: boolean;
  dataDetail: MainSearchDataExtendedDataDetail;
  media: string;
  searchCategories = MainSearchDropdownSearchCategories;
  displayType: string;
  sort = OriginalOrder;
  
  constructor(
    private eRef: ElementRef,
    private searchService: SearchService,
    private router: Router,
    private mediaQueryService: MediaQueryService
  ) { }

  ngOnInit(): void {
    this.mediaQueryService.MEDIA.subscribe((media: string) => this.media = media);
    this.searchService.inputFocus.subscribe((data: boolean) => {
      setTimeout(() => {
        this.setActiveStatus(this.data ? data : false);
      }, 100);
    });
    this.subscriptionStore = this.searchService.store.subscribe((data: MainSearchData): void => {
      if (!data) {
        this.clearData();
        this.setActiveStatus(false);
      } else {
        this.data = this.removeEmpty(data);
        this.extended = this.mapExtendedData(data);
        this.setActiveStatus(true);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptionStore) {
      this.subscriptionStore.unsubscribe();
    }
  }

  clearData(): void {
    this.data = null;
    this.extended = null;
  }

  clearDataDetail(): void {
    this.dataDetail = null;
  }

  setSelected(event: any): void {
    const currentSelected = this.eRef.nativeElement.querySelector('li.selected');
    if (currentSelected) {
      currentSelected.classList.remove('selected');
    }
    event.target.classList.add('selected');
  }

  onClickHandlerSearch(data: ClickedConfig): void {
    if (data.type !== 'news') {
      this.router.navigateByUrl(`/markets/product/isin/${data.value.detail.isin.value}`);
    } else {
      this.router.navigateByUrl(`/news/detail/${data.value.detail.id}`);
    }
    this.searchService.clearSearch();
  }

  onMouseEnterHandlerSearch(data: ClickedConfig, event: any):void {
    this.displayType = this.searchCategories.indexOf(data.type) !== -1 ? 'search' : 'news';
    this.setSelected(event);
    this.dataDetail = data;
  }

  // onMouseEnterHandlerExtended(data: ClickedConfig, event: any):void {
  //   this.displayType = 'extended';
  //   this.setSelected(event);
  //   this.dataDetail = {
  //     type: 'extended',
  //     ...data.value
  //   };
  // }

  setActiveStatus(data: boolean): void {
    this.active = data;
    this.changed.emit({ type: 'MainSearchDropdownState', value: data });
  }

  removeEmpty(data: MainSearchData): MainSearchData {
    const cleanCategories = Object.keys(data).filter((c: string) => data[c].data);
    let cleanData = {};
    cleanCategories.forEach((c: string) => {
      cleanData[c] = data[c];
    });
    return cleanData;
  }

  mapExtendedData(data: MainSearchData): MainSearchDataExtendedData[] {
    const cleanData: MainSearchDataExtendedData[] = [];
    const stockMarketsData = {
      label: 'stocksMarkets',
      values: []
    }
    Object.keys(data).forEach((k: string) => {
      if (data[k].extendedSearch) {
        cleanData.push({
          label: k,
          values: data[k].extendedSearch
        });
      } else if (data[k].count) {
        stockMarketsData.values.push({
          label: k,
          count: data[k].count
        })
      }
    });
    if (stockMarketsData.values.length) {
      cleanData.push(stockMarketsData);
    }
    return cleanData
  }
  
}