import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from '../services/data-service/data.service';
import { MainSearchData } from './search.config';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  config: BehaviorSubject<any> = new BehaviorSubject(null);
  store: BehaviorSubject<MainSearchData> = new BehaviorSubject(null);
  readonly currentSearch: BehaviorSubject<string> = new BehaviorSubject(null);
  readonly searchTerm: BehaviorSubject<string> = new BehaviorSubject('');
  readonly loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  inputFocus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  
  constructor(
    private dataService: DataService
  ) {}

  getData(searchTerm: string): any {
    this.loading.next(true);
    this.dataService.getData(
      '',
      '',
      'instrument',
      '',
      `search/${searchTerm}`
    ).subscribe({
      next: (data: MainSearchData) => {
        this.store.next(data);
        this.loading.next(false);
      },
      error: (error: string) => console.log(error)
    });
  }

  clearSearch(): void {
    this.store.next(null);
    this.searchTerm.next(null);
  }

  setCurrentSearch(data: string): void {
    this.currentSearch.next(data);
  }

  setInputFocus(data: boolean): void {
    this.inputFocus.next(data);
  }

  setSearchTerm(searchTerm: string): void {
    this.searchTerm.next(searchTerm.trim());
    if (searchTerm.length > 1) {
      this.getData(searchTerm);
    } else if (!searchTerm.length) {
      this.clearSearch();
    }
  }
}
