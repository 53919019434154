<h2>{{group|translate}}</h2>
<table aria-describedby="basedata" class="shrinked">
  <tbody>
    <tr *ngFor="let item of properties">
      <td class="label" *ngIf="item.label === 'subCategory' || item.label === 'strategy'">
        {{item.label + '.' + data.instrumentGroup.value|translate}}
      </td>
      <td class="label" *ngIf="item.label !== 'subCategory' && item.label !== 'strategy'">
        {{item.label|translate}}
      </td>
      <td class="value">
        <app-property-renderer [data]="data[item.label]" [config]="item.config"></app-property-renderer>
      </td>
    </tr>
  </tbody>
</table>
