import { Component, OnInit } from '@angular/core';
import { Navigation, NavigationConfig } from './../main-navigation.config';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss']
})
export class MainNavigationComponent implements OnInit {
  categories: Array<Navigation> = NavigationConfig;
  mainnaviActive: string;

  constructor(private router: Router) {}

  toggleAll() {
    this.categories.forEach((c) => {
      if (c.entries) {
        c.entries.filter((e: any) => e.active).forEach((i: any) => {
          i.active = false;
        });
      }
    });
  }

  toggleSubNavi(item: any) {
    if (item.active) {
      this.toggleAll();
      item.active = false;
    } else {
      this.toggleAll();
      item.active = true;
    }
  }

  ngOnInit(): void {
    this.mainnaviActive = this.router.url.split('/')[1];
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.mainnaviActive = event.url.split('/')[1];
      });
  }
}
