import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})

export class TimePickerComponent {
  @Input() data: string;
  @Input() label: string;
  @Output() changeTime: EventEmitter<any> = new EventEmitter();
  timeout: any;

  submit(value: string): void {
    this.changeTime.emit(value);
  }
}
