import PK from '../../../../../ngx-packages/obfuscated-websocket-keys/privateKey.prod';
import { getTracking, getWebsocketHost, isProd } from './environemnt.helper';

const getBackendHost = (hostname: string, websocket?: boolean): string => {
  return isProd(hostname) ? getWebsocketHost(websocket) : location.hostname;
};

export const environment = {
  production: true,
  apiUrl: `https://${getBackendHost(location.hostname)}/api`,
  apiNews: {
    graphql: `https://${getBackendHost(location.hostname)}/api/news/graphql`,
    service: `https://${getBackendHost(location.hostname)}/api/news/page-api/news`,
    siteVariant: 'sv-de'
  },
  apiSearch: `https://${getBackendHost(location.hostname)}/api/instrument/search/`,
  derivativesSearch: {
    setup: `https://${getBackendHost(location.hostname)}/api/ivestor-de-search/setup/ivestor/de`,
    query: `https://${getBackendHost(location.hostname)}/api/ivestor-de-search/query/ivestor/de?q=`
  },
  tracking: getTracking(location.hostname, 1),
  tracing: {
    url: isProd(location.hostname) ? `https://opentracing.prod.fra1.solvians.com/trace` : `https://opentracing.test.fra1.solvians.com/trace`,
    header: 'ivestor-tracking-id'
  },
  websockets: {
    api: `wss://${getBackendHost(location.hostname, true)}/search/ws/qs-push`,
    application: 'de',
    user: 'ivestorPublicDisplay',
    pk: PK,
    scope: 'pricestream',
    sub: 'ivestor'
  }
};