<div class="component">
  <h2 *ngIf="config?.title">{{config.title|translate}}</h2>
  <table [attr.aria-describedby]="config.title">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col" class="label" *ngFor="let item of data">
          <span>{{item[config.columnField]|translate}}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let prop of config.properties">
        <td class="label">{{prop.label | translate}}</td>
        <td *ngFor="let item of data" [ngClass]="getCSSClass(prop, item[prop.id], 0)">
          <app-property-renderer [data]="item[prop.label]" [config]="prop"></app-property-renderer>
        </td>
      </tr>
    </tbody>
    <tfoot *ngIf="config.footer">
      <tr>
        <th scope="colgroup" [attr.colspan]="data.length">
          {{config.footer.label|translate}}
        </th>
      </tr>
    </tfoot>
  </table>

  <p class="more" *ngIf="config?.moreText">
    <app-button-more [label]="config.moreText|translate" [url]="config.showMore"></app-button-more>
  </p>
</div>
