import { SmartSearchConfig } from '@solvians-frontend-ivestor/smart-search/lib/smart-search.config';
import { environment } from '../../environments/environment';
import { ClickedConfig } from '../app.config';
import { SmartSearchTranslations } from './search.translations';

export interface MainSearchData {
  stocks?: MainSearchDataSingle;
  news?: MainSearchDataSingle;
}

export interface MainSearchDataSingle {
  data: MainSearchDataSingleData[];
  count: number;
  extendedSearch?: any[];
}

export interface MainSearchDataSingleData {
  label: string;
  detail: MainSearchDataSingleDataDetail;
}

export interface MainSearchDataSingleDataDetail {
  body?: string;
  id?: string;
}

export interface MainSearchDataExtendedData {
  label: string;
  values: any[];
}

export interface MainSearchDataExtendedDataDetail extends ClickedConfig {
  label?: string;
}

export const SearchConfig: SmartSearchConfig = {
  api: {
    setup: environment.derivativesSearch.setup,
    query: environment.derivativesSearch.query
  },
  lang: 'de',
  urlScheme: '/markets/search/{searchTerm}',
  buzzwords: [
    'daimler optionsschein put',
    'maximale rendite 10%',
    'knockout long',
    'Allianz Bonus cap abstand barriere 10%',
    'eurostoxx 50 von premiumpartner',
    'dax discount cap 14000',
    'abstand ko-barriere 5%'
  ],
  blackList: [
    'partner.fee',
    'partner.trading.place',
    'underlying.currency',
    'product.hit.rate'
  ],
  chart: {
    timespan: '1D',
    trend: true,
    chartType: 'line',
    timespans: [{
      name: '1D',
      value: '1D',
      selected: true
    }, {
      name: '1W',
      value: '1W'
    }, {
      name: '1M',
      value: '1M'
    }, {
      name: '6M',
      value: '6M'
    }, {
      name: '1Y',
      value: '1Y'
    }, {
      name: '5Y',
      value: '5Y'
    }]
  },
  translations: SmartSearchTranslations
};
