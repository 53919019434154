import {
  NewsDTO
} from '@solvians-frontend-ninjas/news-api';
import {
  createExtendedTableConfig
} from '../sharedComponents/extended-table/extended-table';

export interface NewsTilesConfig {
  slider: number;
  latestTiles: number;
  previewTiles: number;
  textTiles: number;
}

export const NewsCategories: any[] = [{
  active: false,
  name: 'Top-News',
  id: 'topnews'
}, {
  active: false,
  name: 'Marktberichte',
  id: 'markets'
}, {
  active: false,
  name: 'Unternehmen',
  id: 'companies'
}, {
  active: false,
  name: 'Adhoc Meldungen',
  id: 'adhoc'
}, {
  active: false,
  name: 'Analysen',
  id: 'analysis'
}, {
  active: false,
  name: 'Devisen',
  id: 'currencies'
}, {
  active: false,
  name: 'Rohstoffe',
  id: 'commodities'
}, {
  active: false,
  name: 'Anleihen',
  id: 'bonds'
}, {
  active: false,
  name: 'Wirtschaft',
  id: 'economy'
}, {
  active: false,
  name: 'Politik',
  id: 'banks_politics'
}];

export const NewsTilesData: any = {
  full: {
    mobile: {
      slider: 5,
      latestTiles: 4,
      previewTiles: 1,
      textTiles: 5
    },
    small: {
      slider: 5,
      latestTiles: 4,
      previewTiles: 1,
      textTiles: 5
    },
    medium: {
      slider: 5,
      latestTiles: 4,
      previewTiles: 3,
      textTiles: 5
    },
    regular: {
      slider: 5,
      latestTiles: 4,
      previewTiles: 4,
      textTiles: 5
    }
  },
  normal: {
    mobile: {
      slider: 0,
      latestTiles: 4,
      previewTiles: 1,
      textTiles: 5
    },
    small: {
      slider: 0,
      latestTiles: 4,
      previewTiles: 1,
      textTiles: 5
    },
    medium: {
      slider: 0,
      latestTiles: 4,
      previewTiles: 3,
      textTiles: 5
    },
    regular: {
      slider: 0,
      latestTiles: 4,
      previewTiles: 4,
      textTiles: 5
    }
  }
};

export const NewsCategoryTypes: any[] = [{
  name: 'europe',
  data: [],
}, {
  name: 'america',
  data: []
}, {
  name: 'asia',
  data: []
}];

export interface NewsDetail extends NewsDTO {
  countries: string[];
  img?: string;
  category?: any;
  news?: any[];
}

export const NewsInvestmentIdeasConfig = createExtendedTableConfig({
  tableSorting: {
    id: 'name',
    sorting: 'asc'
  },
  properties: [{
    childs: [{
      label: 'name',
      id: 'name',
      type: 'text',
      instrumentLink: true
    }]
  }, {
    childs: [{
        label: 'quote',
        id: 'price',
        type: 'price',
        currency: true
      },
      {
        label: 'change2PreviousClose',
        id: 'performance',
        type: 'performance',
        src: 'value',
        currency: true,
        calcPerformanceClass: true
      }
    ]
  }, {
    childs: [{
      label: 'time',
      id: 'price',
      type: 'date',
      src: 'time',
      format: 'mediumTime'
    }, {
      label: 'performance',
      id: 'performance',
      type: 'performance',
      src: 'percent',
      calcPushClass: true
    }]
  }]
});
