import {
  Pipe,
  PipeTransform
} from '@angular/core';
import { InstrumentGroupTypes } from 'src/app/services/instrument-service/instrument.config';

@Pipe({
  name: 'minMax'
})
export class MinMaxPipe implements PipeTransform {

  transform(value: any, type?: InstrumentGroupTypes, base?: number): string {
    let decimals: number;
    if (!value) {
      return '1.2-2';
    }

    switch (type) {
      case 'equities':
      case 'stock':
        decimals = this.getFormat234(value);
        break;
      case 'certificates':
      case 'warrants':
      case 'kos':
        decimals = this.getBalanced(value, base);
        break;
      case 'currencies':
        decimals = 4;
        break;
      case 'commodities':
      case 'indexes':
      case 'futures':
        decimals = this.getFormat23(value);
        break;
      default:
        decimals = 2;
        break;
    }
    return `1.${decimals}-${decimals}`;
  }


  /**
   * Default = 2 decimals
   * if the quote is under 20 and over 0,001 = 3 decimals
   * if the quote is under 0,001 = 4 decimals
   */
  getFormat234(value: number): number {
    let decimals: number;
    if (value < 20 && value > 0.001) {
      decimals = 3;
    } else if (value < 0.001) {
      decimals = 4;
    } else {
      decimals = 2;
    }
    return decimals;
  }

  /**
   * Balance out value and base with max available decimals
   * Example: bid 0.05 and ask 0.1 -> the bid should set be 0.10
   */
  getBalanced(value: number, base: number): number {
    let decimals: number;
    let decimalLength: number;
    const minimum = 2;
    const maximum = 4;
    if (base) {
      decimals = Math.max(Math.min(base, maximum), minimum);
    } else {
      const decString = value.toString().split('.');
      decimalLength = decString.length > 1 ? decString[1].length : 0;
      decimals = Math.max(Math.min(decimalLength, maximum), minimum);
    }
    return decimals;
  }

  /**
   * Default = 2 decimals
   * if the quote is under 10 = 3 decimals
   */
  getFormat23(value: number) {
    let decimals: number;
    if (value !== 0 && value < 10) {
      decimals = 3;
    } else {
      decimals = 2;
    }
    return decimals;
  }

}
