import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabsComponent } from 'src/app/sharedComponents/tabs/tabs.component';
import { TabsStaticComponent } from 'src/app/sharedComponents/tabs-static/tabs-static.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { GetByPropPipe } from 'src/app/pipes/getByProp/get-by-prop.pipe';
import { SelectListComponent } from 'src/app/sharedComponents/select-list/select-list.component';
import { PropertyRendererComponent } from 'src/app/sharedComponents/property-renderer/property-renderer.component';
import { PerformanceRendererComponent } from 'src/app/sharedComponents/performance-renderer/performance-renderer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimpleTableComponent } from '../../sharedComponents/simple-table/simple-table.component';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PreloaderModule } from '../../sharedComponents/preloader/preloader.modules';
import { ButtonMoreComponent } from '../../sharedComponents/button-more/button-more.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TimePickerComponent } from '../../sharedComponents/time-picker/time-picker.component';
import { DatePickerComponent } from '../../sharedComponents/date-picker/date-picker.component';
import { FunctionBarComponent } from '../../sharedComponents/function-bar/function-bar.component';
import { BreadcrumbComponent } from 'src/app/sharedComponents/breadcrumb/breadcrumb.component';
import { ExtendedTableComponent } from 'src/app/sharedComponents/extended-table/extended-table.component';
import { ListTilesComponent } from 'src/app/sharedComponents/list-tiles/list-tiles.component';
import { SortByPipe } from 'src/app/pipes/sortBy/sort-by.pipe';
import { FiltersComponent } from 'src/app/sharedComponents/filters/filters.component';
import { FilterSelectComponent } from '../../sharedComponents/filter-select/filter-select.component';
import { NewsCategoriesComponent } from 'src/app/news/categories/news-categories.component';
import { NewsPreviewComponent } from 'src/app/news/news-preview/news-preview.component';
import { ImageSliderComponent } from 'src/app/sharedComponents/images-slider/image-slider.component';
import { NewsTextComponent } from 'src/app/news/news-text/news-text.component';
import { ShareModule } from 'ngx-sharebuttons';
import { TabsMobileComponent } from 'src/app/sharedComponents/tabs-mobile/tabs-mobile.component';
import { TabsMobileActionsheetComponent } from 'src/app/sharedComponents/tabs-mobile/tabs-mobile-actionsheet/tabs-mobile-actionsheet.component';
import { MarketOverviewComponent } from 'src/app/sharedComponents/market-overview/market-overview.component';
import { CustomDateSelectComponent } from 'src/app/sharedComponents/custom-date-select/custom-date-select.component';
import { FilterInputComponent } from 'src/app/sharedComponents/filter-input/filter-input.component';
import { UnderlyingSelectComponent } from 'src/app/sharedComponents/underlying-select/underlying-select.component';
import { SelectMonthComponent } from 'src/app/sharedComponents/select-month/select-month.component';
import { FiltersDateComponent } from '../../sharedComponents/filters-date/filters-date.component';
import { FiltersDateMobileComponent } from '../../sharedComponents/filters-date-mobile/filters-date-mobile.component';
import { ChartLibComponent } from 'src/app/sharedComponents/chart-lib/chart-lib.component';
import { ColumnTableComponent } from 'src/app/sharedComponents/column-table/column-table.component';
import { CutWordPipe } from 'src/app/pipes/cutWord/cut-word.pipe';
import { CompanyProfileComponent } from './overview/companyProfile/company-profile.component';
import { BasedataComponent } from './overview/basedata/basedata.component';
import { AssociatedDerivativesComponent } from './overview/associated-derivatives/associated-derivatives.component';
import { DonutChartComponent } from 'src/app/sharedComponents/donut-chart/donut-chart.component';
import { ErrorComponent } from 'src/app/sharedComponents/error/error.component';
import { FlyoutTableRowComponent } from 'src/app/sharedComponents/flyout-table-row/flyout-table-row.component';
import { DateOrTimePipe } from '@solvians-frontend-ivestor/pipes';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatBottomSheetModule,
    MatListModule,
    MatMenuModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    PreloaderModule,
    NgxMaterialTimepickerModule,
    ShareModule,
    FlyoutTableRowComponent,
    DateOrTimePipe
  ],
  declarations: [
    TabsComponent,
    TabsMobileComponent,
    TabsMobileActionsheetComponent,
    TabsStaticComponent,
    SelectListComponent,
    GetByPropPipe,
    PropertyRendererComponent,
    PerformanceRendererComponent,
    SimpleTableComponent,
    ColumnTableComponent,
    ExtendedTableComponent,
    ButtonMoreComponent,
    TimePickerComponent,
    DatePickerComponent,
    FunctionBarComponent,
    BreadcrumbComponent,
    ListTilesComponent,
    SortByPipe,
    FiltersComponent,
    FilterSelectComponent,
    FilterInputComponent,
    NewsCategoriesComponent,
    NewsPreviewComponent,
    ImageSliderComponent,
    NewsTextComponent,
    MarketOverviewComponent,
    CustomDateSelectComponent,
    UnderlyingSelectComponent,
    FiltersDateComponent,
    FiltersDateMobileComponent,
    SelectMonthComponent,
    ChartLibComponent,
    CutWordPipe,
    CompanyProfileComponent,
    BasedataComponent,
    AssociatedDerivativesComponent,
    DonutChartComponent,
    ErrorComponent
  ],
  exports: [
    TabsComponent,
    TabsMobileComponent,
    TabsMobileActionsheetComponent,
    TabsStaticComponent,
    RouterModule,
    TranslateModule,
    SelectListComponent,
    GetByPropPipe,
    PropertyRendererComponent,
    PerformanceRendererComponent,
    FormsModule,
    SimpleTableComponent,
    ColumnTableComponent,
    ExtendedTableComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatBottomSheetModule,
    MatListModule,
    MatMenuModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    PreloaderModule,
    ButtonMoreComponent,
    NgxMaterialTimepickerModule,
    TimePickerComponent,
    DatePickerComponent,
    FunctionBarComponent,
    BreadcrumbComponent,
    ListTilesComponent,
    SortByPipe,
    FiltersComponent,
    FilterSelectComponent,
    FilterInputComponent,
    NewsCategoriesComponent,
    NewsPreviewComponent,
    ImageSliderComponent,
    NewsTextComponent,
    ShareModule,
    MarketOverviewComponent,
    CustomDateSelectComponent,
    UnderlyingSelectComponent,
    FiltersDateComponent,
    FiltersDateMobileComponent,
    SelectMonthComponent,
    ChartLibComponent,
    CutWordPipe,
    CompanyProfileComponent,
    BasedataComponent,
    AssociatedDerivativesComponent,
    DonutChartComponent,
    ErrorComponent,
    DateOrTimePipe
  ]
})
export class ProductSharedModule {}
