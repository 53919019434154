<h2 *ngIf="config">{{config.title|translate}}</h2>
<ul>
  <li *ngFor="let item of data; let i = index;">
    <div class="summary">
      <div class="label">{{item.label|translate}}</div>
      <div class="value">{{item.count|number:'1.0'}}</div>
      <div class="suffix">{{config.suffix|translate}}</div>
    </div>
    <app-donut-chart [data]="item" [config]="config"></app-donut-chart>
    <table aria-describedby="associatedDerivatives">
      <thead *ngIf="config.properties">
        <tr>
          <th scope="col" *ngFor="let item of config.properties">{{item.label|translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let val of item.values | slice:0:limit[i]" class="fadeIf" (click)="triggerSearch(val)">
          <td>{{val.label}}</td>
          <td *ngIf="!showPercents">{{val.count|number}}</td>
          <td *ngIf="showPercents">{{val.percent|number:'1.2-2'}}</td>
        </tr>
      </tbody>
    </table>
    <p class="more" *ngIf="showMoreButton[i]">
      <a class="button text" [ngClass]="{'toggled': limit[i] > config.limitMin}" (click)="setLimit(i)">
        {{limit[i] > config.limitMin ? ('less'|translate) : 'more'|translate}} {{'associated.derivates.more'|translate}}
      </a>
    </p>
  </li>
</ul>
