import { Component, OnInit } from '@angular/core';
import { MediaQueryService } from '../services/mediaquery-service/mediaquery-service.service';
import { FavoritesService } from '../tools/favorites/favorites.service';
import { Instrument } from '../services/instrument-service/instrument.config';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  naviType: string = '';
  showMobileNavi: boolean = false;
  favoriteActive: boolean = false;

  constructor(
    public mediaQueryService: MediaQueryService,
    public favoritesService: FavoritesService
  ) {}

  toggleMobileNavigation(): void {
    this.showMobileNavi = !this.showMobileNavi;
  }

  ngOnInit(): void {
    this.mediaQueryService.MEDIA.subscribe((media: string) => {
      this.naviType = media === 'regular' ? 'regular' : 'mobile';
    });
    this.favoritesService.DATA.subscribe((data: Instrument[]) => this.favoriteActive = data.length ? true : false)
  }
}
