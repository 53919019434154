export interface MainSearchInput {
  categories: MainSearchCategory[];
}

export interface MainSearchCategory {
  name: string;
  value: string;
  values: MainSearchCategoryValue[];
  category?: any;
}

export interface MainSearchCategoryValue {
  name: string;
  value: string;
  selected?: boolean;
}

export const MainSearchInputConfig: MainSearchInput = {
  categories: [{
      name: 'stocks.markets',
      value: 'stocksMarkets',
      values: [{
        name: 'derivatives',
        value: 'derivatives'
      }]
    }
  ]
}
