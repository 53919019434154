import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [ 
    CommonModule,
    RouterModule
  ],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  year: string;

  ngOnInit(): void {
    this.year = new Date().getFullYear().toString();
  }

}
