import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as Chartist from 'chartist';
import { PushService } from 'src/app/push/push.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChartDataConfig, ChartIdentifierConfig } from '@solvians-frontend-ninjas/core-charts';
import { CustomChartConfig, CustomChartService } from './CustomChartService';

@Component({
  selector: 'app-lib-chart',
  template: '<div *ngIf="error" class="chart-error"><span class="message">{{\'chart.error\'|translate}}</span></div><div *ngIf="showChart" class="ct-line-chart" [ngClass]="styles" #libChart></div><div class="ct-scrollbar-wrapper"></div>'
})
export class ChartLibComponent implements OnInit, OnChanges, OnDestroy {
  destroy$: Subject<boolean>;
  @Input() identifier!: ChartIdentifierConfig;
  @Input() config?: CustomChartConfig;
  @Input() staticData?: ChartDataConfig;
  @Input() parent?: string;
  @Output() changed?: EventEmitter<any> = new EventEmitter();
  @ViewChild('libChart') libChart: ElementRef;
  chart: any;
  data!: ChartDataConfig;
  error: string;
  styles: string;
  showChart: boolean;
  liveChartInterval: any;

  constructor(
    private chartService: CustomChartService,
    private changeDetectorRef: ChangeDetectorRef,
    private pushService: PushService
  ) {}

  ngOnInit(): void {
    if (!this.identifier) {
      console.error('Missing identifier');
    } else {
      this.staticData ? this.drawChart(this.staticData) :
      this.chartService.getData(
        this.identifier,
        this.chartService.getUrlParams(this.config)
      )
        .then((data: any) => {
          if (!data.code && data.series[0].data.length) {
            this.drawChart(data);
          } else {
            this.showError(data);
          }
        })
        .catch(() => console.log('chart init aborted'));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.config?.firstChange) {
      this.ngOnInit();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeFromPush();
  }

  unsubscribeFromPush(): void {
    if (this.destroy$?.observers?.length > 0) {
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
    }
    clearInterval(this.liveChartInterval);
  }

  drawChart(data: ChartDataConfig): void {
    this.unsubscribeFromPush();
    this.showChart = false;
    this.error = null;
    const isRelative = data.series.length > 1 || this.config.resolution === 'relative';
    const shouldHavePushUpdates = (this.config.timespan === '1D' || this.config.timespan === '1DL') && this.config.chartType !== 'ohlc';
    const chartData = isRelative ? this.chartService.dataFormatterRelative(data) : this.chartService.dataFormatter(data);
    if (this.config.chartType !== 'volume' && this.config.timespan === '1DL') {
      chartData.lines = [];
      chartData.series.forEach((series: any) => {
        series.data = this.chartService.setLiveChartData(
          this.chartService.getLastDataPoint([series]),
          this.config.chartType
        );
      });
    }

    this.config.parent = this.parent ? this.parent : null;
    const config = this.chartService.setCustomConfig(this.chartService.getConfig(chartData, this.config), data);
    this.showChart = true;
    this.changeDetectorRef.detectChanges();
    const chartistType = this.config.chartType === 'volume' ? 'Bar' : 'Line';
    this.chart = new Chartist[chartistType](
      this.libChart.nativeElement,
      chartData,
      config,
      config.responsiveOptions
    );
    if (shouldHavePushUpdates && !this.staticData) {
      this.setPushUpdates(this.chart, this.config, chartData);
    }
    this.changed.emit(chartData);
  }

  setPushUpdates(chart: any, config: CustomChartConfig, chartData: ChartDataConfig): void {
    this.destroy$ = new Subject<boolean>();
    this.pushService.add(this.chartService.getSubscriptions(chartData));
    if (config.timespan === '1DL') {
      this.liveChartInterval = setInterval(() => {
        this.chartService.updateLiveChartData(this.pushService.DATA.getValue(), chart, config?.pushPriceProperty);
        this.changed.emit(chartData);
      }, 1000);
      return;
    }

    this.pushService.data$
      .pipe(takeUntil(this.destroy$))
      .subscribe((push: any) => {
        this.chartService.updateData(push, chart);
      });
  }

  showError(error: string): void {
    this.showChart = false;
    this.data = null;
    this.error = error;
    this.changed.emit(false);
  }
}
