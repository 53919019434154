import { DecimalPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { getPriceDecimals } from "src/app/helper/helper";
import { MinMaxPipe } from "src/app/pipes/minMax/min-max.pipe";
import { ShortNumberPipe } from "src/app/pipes/shortNumber/short-number.pipe";
import { InstrumentGroupTypes } from "src/app/services/instrument-service/instrument.config";
import { PropertyRendererConfig } from "./property-renderer.config";

@Injectable({
  providedIn: 'root'
})
export class PropertyRendererService {

  constructor(
    private decimalPipe: DecimalPipe,
    private shortNumber: ShortNumberPipe,
    private minMaxPipe: MinMaxPipe
  ) {}

  getDecimals(data: number, instrumentGroup: InstrumentGroupTypes): string {
    const maxPriceDecimals: number = getPriceDecimals(data);
    return this.minMaxPipe.transform(
      data,
      instrumentGroup,
      maxPriceDecimals
    );
  }

  getCurrency(data: any, config: any): string {
    const staticSuffix = typeof config.currency === 'string' ? config.currency : '';
    return data?.currency?.value || data?.currency || data?.market?.currency || staticSuffix;
  }

  renderPerformance(data: any, config: PropertyRendererConfig, instrumentGroup: InstrumentGroupTypes): string {
    let valueStr: string;
    let value = config.src ? data[config.src] : data;
    if (!value) {
      return '-';
    }
    value = value === Infinity ? 0 : value;
    const decimals = config.decimalConfig || this.getDecimals(value, instrumentGroup);

    if (config.src === 'value') {
      valueStr = this.decimalPipe.transform(
        value,
        decimals
      );
    } else {
      valueStr = this.decimalPipe.transform(
        value,
        '1.2-2'
      ) + ' %';
    }
    return `${value > 0 ? '+' : ''}${valueStr}`;
  }

  renderPrice(data: any, config: PropertyRendererConfig, instrumentGroup: InstrumentGroupTypes): string {
    let valueStr: string;
    const value = data.hasOwnProperty('value') ? data.value : data;
    if (!Number(value)) { return '-' }
    const decimals = config.decimalConfig || this.getDecimals(value, instrumentGroup);
    valueStr = this.decimalPipe.transform(
      value,
      decimals
    );
    if (!config.decimalConfig && !instrumentGroup) {
      console.log('instrument group missing for: ' + value);
    }
    return valueStr;
  }

  renderVolume(data: any, config?: PropertyRendererConfig): string {
    let valueStr: string;
    const value = config.src ? data[config.src] : (data?.size || data?.value || data);

    if (value < 1000000) {
      valueStr = this.decimalPipe.transform(
        value,
        '1.0-0'
      );
    } else {
      valueStr = this.shortNumber.transform(
        value
      );
    }
    return valueStr ? valueStr : '-';
  }

  renderText(data: any, config?: PropertyRendererConfig): string {
    const value = data[config.src] || data.value || data;
    return value.toString();
  }
}