<div class="component">
  <p *ngIf="error">{{error|translate}}</p>
  <ul class="alphabet">
    <li (click)="setSorting()">
      <button [ngClass]="{'active': !sorting}">{{'all'|translate}}</button>
    </li>
    <li *ngFor="let item of config.alphabet" (click)="setSorting(item)">
      <button [ngClass]="{'active': sorting === item}">{{item}}</button>
    </li>
    <li (click)="setSorting('0-9')">
      <button [ngClass]="{'active': sorting === '0-9'}">0-9</button>
    </li>
  </ul>

  <div class="content-wrapper">
    <div class="underlying-list" [ngClass]="{'locked':locked}">
      <ul class="result" *ngFor="let item of filteredUnderlyings">
        <li class="label">{{item.label}}</li>
        <li></li>
        <li></li>
        <li *ngFor="let item of item.values" [ngClass]="{'selected': item.selected}" (click)="selectHandler(item)">
          <span class="value">{{item.name.value}}</span>
        </li>
      </ul>
    </div>
    <div class="buttons">
      <button class="primary green" (click)="submitHandler()">{{'apply'|translate}}</button>
      <button class="secondary ghost" (click)="closeHandler()">{{'cancel'|translate}}</button>
    </div>
  </div>
</div>
