import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { FavoritesService } from 'src/app/tools/favorites/favorites.service';

@Component({
  standalone: true,
  selector: 'app-flyout-table-row',
  templateUrl: './flyout-table-row.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [ 
    TranslateModule,
    MatMenuModule
  ]
})
export class FlyoutTableRowComponent {
  @Input() config: any;
  @Input() data: any;

  constructor(
    private favoritesService: FavoritesService
  ) {}
  
  onClickHandler():void {
    this.favoritesService.deleteInstrument(this.data.isin.value);
  }

}
