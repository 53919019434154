import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { GetTableCSSClass, GetTableLabelCSSClass, SetupSorting } from 'src/app/helper/helper';

@Component({
  selector: 'app-extended-table',
  templateUrl: './extended-table.component.html',
  styleUrls: ['./extended-table.component.scss', './extended-table.group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtendedTableComponent implements OnChanges {
  @Input() data: any;
  @Input() config: any;
  @Input() group: string;
  @Output() sorting: EventEmitter<any> = new EventEmitter();
  getCSSClass = GetTableCSSClass;
  getLabelClass = GetTableLabelCSSClass;

  ngOnChanges(): void {
    SetupSorting(this.config.properties, this.config.tableSorting);
  }

  resetSorting = (properties: any): void => {
    properties.forEach((p: any) => {
      p.childs.forEach((c: any) => c.sorting = null);
    });
  }

  getSelectedClass(data: any): string {
    return data.filter((c: any) => c.sorting).length ? 'selected' : '';
  }

  getSortingDirection(item: any): string {
    let sortStr = 'desc';
    if (!item.sorting || item.sorting === 'desc') {
      sortStr = 'asc';
    }
    return sortStr;
  }

  sortTable(item: any) {
    const newSorting = this.getSortingDirection(item);
    this.resetSorting(this.config.properties);
    item.sorting = newSorting;
    this.config.tableSorting = {
      id: `${item.id}${item.src ? '.' + item.src : ''}`,
      sorting: newSorting
    };
    this.sorting.emit(this.config.tableSorting);
  }
}
