import { TableConfig } from "src/app/sharedComponents/extended-table/extended-table";

export const MainSearchDropdownSearchCategories: string[] = ['stocks', 'derivatives', 'indices'];

export const MainSearchDropdownConfig: TableConfig = {
  properties: [{
    childs: [{
      label: 'name',
      id: 'name',
      type: 'text',
      instrumentLink: true
    }]
  }, {
    childs: [{
      label: 'chart',
      id: 'chart',
      type: 'chart',
      url: '/chart/sin'
    }]
  }, {
    childs: [{
        label: 'quote',
        id: 'price',
        type: 'price',
        currency: true
      },
      {
        label: 'performance',
        id: 'performance',
        type: 'performance',
        src: 'value',
        currency: true,
        calcPerformanceClass: true
      }
    ]
  }, {
    childs: [{
      label: 'time',
      id: 'price',
      type: 'dateOrTime',
      src: 'time',
      format: 'mediumTime'
    }, {
      label: 'performancePercent',
      id: 'performance',
      type: 'performance',
      src: 'percent',
      calcPushClass: true
    }]
  }]
}
