import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {
  readonly data: Subject<string> = new Subject();

  add(data: string) {
    this.data.next(data);
  }
}
