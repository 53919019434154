import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CutWordPipe } from 'src/app/pipes/cutWord/cut-word.pipe';
import { ImageService } from 'src/app/services/image-service/image-service.service';
import { MediaQueryService } from '../../services/mediaquery-service/mediaquery-service.service';
import { NewsDetail } from '../news.config';

@Component({
  selector: 'app-news-preview',
  templateUrl: './news-preview.component.html',
  styleUrls: ['./news-preview.component.scss']
})
export class NewsPreviewComponent implements OnInit {
  @Input() data: NewsDetail;

  constructor(
    private router: Router,
    private mediaQueryService: MediaQueryService,
    private randomImageservice: ImageService,
    private cutWordPipe: CutWordPipe
  ) { }

  ngOnInit(): void {
    this.mediaQueryService.MEDIA.subscribe((media: string) => {
      setTimeout(() => this.data.img = this.randomImageservice.getImage(this.data, media));
      this.data = {
        ...this.data,
        title: this.cutWordPipe.transform(this.data.title, true, '140', ' ...')
      };
    });
  }

  navigateTo(id: string) {
    this.router.navigate(['news', 'detail', id]);
  }

}
