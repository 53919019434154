<div class="wrapper">
  <ul>
    <li *ngFor="let item of categories" [ngClass]="{'active': mainnaviActive === item.id}" (click)="toggleNavi(item)">
      <a class="mainnavi {{item.id}}">{{item.name}}</a>
      <ul class="subnavi" *ngIf="item.active">
        <li *ngFor="let sub of item.entries" [ngClass]="{'active': sub.active}">
          <span class="label-menu" *ngIf="sub.list" (click)="toggleSubNavi(sub)">{{sub.name}}</span>
          <a class="label" [routerLink]="[item.id, sub.id]" routerLinkActive="active" *ngIf="!sub.list" (click)="close()">{{sub.name}}</a>
          <ul class="thirdnavi" *ngIf="sub.active">
            <li *ngFor="let third of sub.list">
              <a [routerLink]="[item.id, third.id]" routerLinkActive="active" (click)="close()">{{third.name}}</a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</div>
