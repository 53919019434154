import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { objIsEmpty, GetPushClass } from '../../helper/helper';
import { DecimalPipe, DatePipe, PercentPipe } from '@angular/common';
import { PropertyRendererService } from './property-renderer.service';
import { PropertyRendererConfig } from './property-renderer.config';
import { DateOrTimePipe } from '@solvians-frontend-ivestor/pipes';

@Component({
  selector: 'app-property-renderer',
  template: '<span [innerHTML]="value" [ngClass]="cssClass"></span>',
  styleUrls: ['./property-renderer.component.scss']
})
export class PropertyRendererComponent implements OnChanges {
  @Input() data: any;
  @Input() config: PropertyRendererConfig;
  @Input() item: any;
  value: string;
  cssClass: string;

  constructor(
    private decimalPipe: DecimalPipe,
    private percentPipe: PercentPipe,
    private datePipe: DatePipe,
    private dateOrTimePipe: DateOrTimePipe,
    private propertyRendererService: PropertyRendererService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.config || this.data === undefined || objIsEmpty(this.data)) {
      this.config = {
        type: 'default'
      };
    }
    this.value = this.renderValue();
    if (this.config.push && changes.data && changes.data.previousValue) {
      this.setPushClass(changes.data.previousValue.value, this.data.value);
    }
  }

  setPushClass(prev: number, current: number): void {
    this.cssClass = `push ${GetPushClass(prev, current)}`;
    setTimeout(() => this.cssClass = '', 400);
  }

  renderValue(): string {
    let value: string;
    let percentValue: number;

    switch (this.config.type) {
      case 'volume':
        value = this.propertyRendererService.renderVolume(this.data, this.config);
        break;
      case 'number':
        value = this.data[this.config.src] || this.data?.value?.toString() || this.data?.toString();
        this.config.suffix = this.data.unit ? ` ${this.data.unit}` : null;
        value = this.decimalPipe.transform(
          value,
          this.config.decimalConfig
        );
        break;
      case 'productLevel':
        this.config.suffix = this.data.unit ? ` ${this.data.unit}` : null;
        value = this.data.value ? this.decimalPipe.transform(
          this.data.value,
          '1.2-5'
        ) : '-';
        break;
      case 'text':
        value = this.propertyRendererService.renderText(this.data, this.config);
        break;
      case 'date':
        value = this.datePipe.transform(
          this.data[this.config.src] || this.data.value || this.data,
          this.config.format
        );
        break;
      case 'dateOrTime':
        value = this.dateOrTimePipe.transform(
          this.data[this.config.src] || this.data.value || this.data
        );
        break;
      case 'maturity':
        if (this.data.value && this.data.value === 'Open End') {
          value = this.data.value;
        } else {
          value = this.datePipe.transform(
            this.data.value || this.data,
            this.config.format
          );
        }
        break;
      case 'percents':
        const hasValue = this.data.hasOwnProperty('value') ? this.data.value : this.data;
        percentValue = this.config.src ? this.data[this.config.src] : hasValue;
        value = this.percentPipe.transform(
          percentValue / 100,
          '1.2-2'
        );
        break;
      case 'price':
        value = this.propertyRendererService.renderPrice(this.data, this.config, this.item?.instrumentGroup?.value);
        break;
      case 'performance':
        value = this.propertyRendererService.renderPerformance(this.data, this.config, this.item?.instrumentGroup?.value);
        break;
      case 'market':
        value = this.data[this.config.src];
        break;
      case 'label':
        value = this.config.src;
        break;
      default:
        value = '-';
        break;
    }

    if (this.config.currency) {
      this.config.suffix = ` ${this.propertyRendererService.getCurrency(this.item, this.config)}`;
    }

    // Add suffix
    if (value !== '-' && this.config.type && this.config.suffix) {
      value += this.config.suffix;
    }

    return value;
  }

}
