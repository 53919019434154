(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['chartist'], function (chartist) {
      return (root.returnExportsGlobal = factory(chartist));
    });
  } else if (typeof exports === 'object') {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like enviroments that support module.exports,
    // like Node.
    module.exports = factory(require('chartist'));
  } else {
    root['Chartist.plugins.tooltipDonut'] = factory(root.Chartist);
  }
}(this, function (Chartist) {
  /**
   * Chartist.js plugin to display a custom tooltip for piecharts.
   */

  'use strict';

  var defaultOptions = {};

  Chartist.plugins = Chartist.plugins || {};
  Chartist.plugins.tooltipDonut = (options) => {

    options = Chartist.extend({}, defaultOptions, options);

    return function tooltipDonut(chart) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;

      if (chart instanceof Chartist.Pie) {

        chart.on('created', (_data) => {
          const count = chart.data.series.reduce(reducer);
          const slices = chart.container.querySelectorAll('.ct-slice-donut');
          let tooltipData = {};
          if (!slices) {
            return;
          }

          // Update tooltip content
          function updateContent(slice, index) {
            const seriesCss = slice.parentElement.classList[1],
              suffix = options.suffix || ' Stk.';

            tooltipData.label = chart.data.labels[index];
            tooltipData.value = Number(slice.getAttribute('ct:value')) || null;
            tooltipData.percents = ((100 / count) * tooltipData.value).toFixed(2);
            tooltipData.index = index;

            tooltip.innerHTML = `<span class="${seriesCss} label">${tooltipData.label}</span>`;
            tooltip.innerHTML += `<span class="${seriesCss} value">${tooltipData.value + suffix}</span>`;
            tooltip.innerHTML += `<span class="${seriesCss} percents">${tooltipData.percents}</span>`;
          }

          // Create tooltip wrapper
          let tooltip;
          tooltip = document.createElement('div');
          tooltip.classList.add('ct-tooltip');
          chart.container.appendChild(tooltip);

          if (options.clickFunc) {
            chart.container.addEventListener('click', () => {
              options.clickFunc(tooltipData, options);
            });
          }

          // Add eventlistener
          slices.forEach((slice, index) => {
            slice.addEventListener('mouseenter', (event) => {
              updateContent(event.target, index);
              tooltip.style.display = 'block';
            });
            slice.addEventListener('mouseleave', () => {
              tooltip.style.display = 'none';
            });
          });

          chart.container.addEventListener('mousemove', (event) => {
            const tooltipX = event.clientX || event.pageX;
            const tooltipY = event.clientY || event.pageY;
            tooltip.style.left = tooltipX - options.offsetX + 'px';
            tooltip.style.top = tooltipY - options.offsetY + 'px';
          });
        });
      }
    };
  };

  return Chartist.plugins.tooltipDonut;

}));
