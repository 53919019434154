import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediaQueryService {
  readonly MEDIA: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private breakpointObserver: BreakpointObserver
  ) { }

  init(): void {
    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.TabletPortrait,
      Breakpoints.HandsetLandscape
    ]);

    layoutChanges.subscribe((state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.HandsetPortrait]) {
        this.MEDIA.next('mobile');
      } else if (state.breakpoints[Breakpoints.TabletPortrait]) {
        this.MEDIA.next('small');
      } else if (state.breakpoints[Breakpoints.HandsetLandscape]) {
        this.MEDIA.next('medium');
      } else {
        this.MEDIA.next('regular');
      }
    });
  }

}
