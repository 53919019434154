import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { MediaQueryService } from 'src/app/services/mediaquery-service/mediaquery-service.service';
import { NewsDataService } from '../../services/news-data-service/news-data.service';
import { NewsCategoryTypes, NewsDetail, NewsTilesConfig, NewsTilesData } from '../news.config';

@Component({
  selector: 'app-news-categories',
  templateUrl: './news-categories.component.html',
  styleUrls: ['./news-categories.component.scss']
})
export class NewsCategoriesComponent implements OnInit {
  data: any;
  sliderData: any[];
  error: string;
  id: string;
  latest: any[];
  showPreloader: boolean;
  types = NewsCategoryTypes;
  media: string;
  cmpConfig = NewsTilesData;
  viewConfig: any;
  displayType: string;

  constructor(
    private route: ActivatedRoute,
    private newsDataService: NewsDataService,
    private mediaQueryService: MediaQueryService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params.id;
      this.displayType = this.id === 'topnews' ? 'full' : 'normal';
      if (this.media) {
        this.getData(this.id);
      }
    });
    this.mediaQueryService.MEDIA.subscribe((media: string) => {
      this.media = media;
      this.getData(this.id);
    });
  }

  getData(category: any): void {
    this.showPreloader = true;
    this.error = null;
    const mainCategory = this.newsDataService.getSubjectsByType(category);
    this.viewConfig = this.cmpConfig[this.displayType][this.media];
    this.getTypeData(mainCategory, this.viewConfig)
      .subscribe((data: NewsDetail[]) => {
        this.sliderData = this.displayType === 'full' ? this.setSliderData(data, this.viewConfig) : null;
        this.latest = this.setLatestData(data, this.viewConfig);
        this.setTypeData(data, this.viewConfig);
        this.showPreloader = false;
      });
  }

  getTypeData(mainCategory: string[], viewConfig: NewsTilesConfig): Observable<[NewsDetail]> {
    const params = {
      page: 0,
      rowsPerPage: Object.values(viewConfig).reduce(
        (p: number, c: number) => p + c) * (this.types.length - 1) + 10 // + 10 more to fill up regions
    };
    const subjects = this.types.map((t: any) => this.newsDataService.getSubjectsByType(t.name)).reduceRight(
      (accumulator, currentValue) => accumulator.concat(currentValue)
    ).concat(mainCategory);
    const countries = this.types.map((t: any) => this.newsDataService.getCountriesByType(t.name)).reduceRight(
      (accumulator, currentValue) => accumulator.concat(currentValue)
    ).concat(mainCategory);
    return this.newsDataService.getDataFromGQL({
      ...params,
      subjects,
      countries
    });
  }

  setSliderData(data: NewsDetail[], viewConfig: NewsTilesConfig): NewsDetail[] {
    return this.mapToCategory(data.slice(0, viewConfig.slider));
  }

  setLatestData(data: NewsDetail[], viewConfig: NewsTilesConfig): NewsDetail[] {
    return this.mapToCategory(data.slice(viewConfig.slider, viewConfig.slider + viewConfig.latestTiles));
  }

  setTypeData(data: NewsDetail[], viewConfig: NewsTilesConfig): void {
    const sliceParams = viewConfig.slider + viewConfig.latestTiles;
    const typeData = this.mapToCategory(data.slice(sliceParams, data.length));
    this.types.forEach((t: any) => {
      const countries = this.newsDataService.getCountriesByType(t.name);
      const news: NewsDetail[] = [];
      countries.forEach((c: string) => {
        const matches: any = typeData.filter((d: NewsDetail) => d.countries.includes(c));
        if (matches.length) {
          matches.forEach((match: any) => news.push(match));
        }
      });
      t.data = this.newsDataService.removeDoubles(
        [...news].sort((a: any, b: any) => a.firstCreated - b.firstCreated).reverse()
      );
    });
  }

  mapToCategory(data: NewsDetail[]): NewsDetail[] {
    return this.displayType === 'full' ?
      this.newsDataService.mapToSingleCategory(data) :
      data.map((d: NewsDetail) => {
        return {
          ...d,
          category: this.id
        };
      });
  }

  showError(error: any): void {
    this.data = null;
    this.error = error;
    this.showPreloader = false;
  }
}
