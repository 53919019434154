import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediaQueries } from 'src/app/services/mediaquery-service/mediaquery-service.config';
import { MediaQueryService } from 'src/app/services/mediaquery-service/mediaquery-service.service';
import { BreadcrumbConfig } from './breadcumb.config';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() data: BreadcrumbConfig[];
  media: MediaQueries;
  segment: string;
  width: string;

  constructor(
    private route: ActivatedRoute,
    private mediaQueryService: MediaQueryService
  ) { }

  ngOnInit(): void {
    if (this.data?.length < 3) {
      this.route.url.subscribe((data: any) => this.renderData(data));
    } 
    this.mediaQueryService.MEDIA.subscribe((media: MediaQueries) => {
      this.width = this.setTextLength(media);
    });
  }

  setTextLength(media: MediaQueries): string {
    if (media === 'mobile') {
      return '30';
    } else if (media === 'small') {
      return '60';
    } else {
      return '100';
    }
  }

  renderData(data: any): void {
    if (data.length && data[0].path) {
      this.segment = data[0].path;
    }
  }
}
