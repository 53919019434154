import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NotifierService } from 'src/app/services/notifier-service/notifier.service';

@Component({
  standalone: true,
  selector: 'app-notifier',
  template: '<div class="content" *ngIf="data"><p>{{data}}</p><div>',
  styleUrls: ['./notifier.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [ CommonModule ]
})

export class NotifierComponent implements OnInit {
  @Input() data: string | null;

  constructor(
    private notifierService: NotifierService
  ) {}

  ngOnInit():void {
    this.notifierService.data.subscribe((data: string) => {
      this.data = data;
      setTimeout(() => this.data = null, 3000);
    });
  }
}
