import { NewsCategories } from '../news/news.config';
export interface Navigation {
  name: string;
  id: string;
  active: boolean;
  entries?: NavigationEntry[];
}

export interface NavigationEntry {
  active: boolean;
  name: string;
  id: string;
  list: NavigationListItem[];
}

export interface NavigationListItem {
  name: string;
  id: string;
}

export interface TranslatedURL {
  translated?: boolean;
  realPath: string;
  translatedPath?: string;
  params?: string;
}

export const URLConfig = [
  {
    '/news/topnews': {
      de: '/nachrichten/topnews'
    }
  },
  {
    '/news/markets': {
      de: '/nachrichten/marktberichte'
    }
  },
  {
    '/news/companies': {
      de: '/nachrichten/unternehmen'
    }
  },
  {
    '/news/adhoc': {
      de: '/nachrichten/adhocmeldungen'
    }
  },
  {
    '/news/analysis': {
      de: '/nachrichten/analysen'
    }
  },
  {
    '/news/currencies': {
      de: '/nachrichten/devisen'
    }
  },
  {
    '/news/commodities': {
      de: '/nachrichten/rohstoffe'
    }
  },
  {
    '/news/bonds': {
      de: '/nachrichten/anleihen'
    }
  },
  {
    '/news/economy': {
      de: '/nachrichten/wirtschaft'
    }
  },
  {
    '/news/banks_politics': {
      de: '/nachrichten/politik'
    }
  },
  {
    '/markets/equities/lists/quotes': {
      de: '/aktienlisten/kurse'
    }
  },
  {
    '/markets/equities/lists/highLow': {
      de: '/aktienlisten/hoch-tief'
    }
  },
  {
    '/markets/equities/lists/performance': {
      de: '/aktienlisten/performance'
    }
  },
  {
    '/markets/indizes': {
      de: '/indizes'
    }
  },
  {
    '/imprint': {
      de: '/impressum'
    }
  },
  {
    '/privacy': {
      de: '/datenschutz'
    }
  },
  {
    '/legal': {
      de: '/nutzungsbedingungen'
    }
  },
  {
    '/favorites': {
      de: '/favoriten'
    }
  },
];

export const NavigationConfig: Navigation[] = [{
  name: 'Börse & Märkte',
  id: 'markets',
  active: false,
  entries: [{
      active: false,
      name: 'Aktien',
      id: 'Aktien',
      list: [{
          name: 'Aktienlisten',
          id: 'equities'
        },
        {
          name: 'Nachrichten',
          id: 'news'
        }
      ]
    },
    {
      active: false,
      name: 'Indizes',
      id: 'Indizes',
      list: [{
          name: 'Börsenindizes',
          id: 'indizes'
        },
        {
          name: 'Marktberichte',
          id: 'reports'
        }
      ]
    },

    {
      active: false,
      name: 'Derivate',
      id: 'Derivate',
      list: [
        {
          name: 'Suche',
          id: 'search'
        }
      ]
    },
    {
      active: false,
      name: 'Devisen',
      id: 'Devisen',
      list: [{
          name: 'Nachrichten',
          id: 'currencies'
        }
      ]
    },
    {
      active: false,
      name: 'Rohstoffe',
      id: 'Rohstoffe',
      list: [
        {
          name: 'Nachrichten',
          id: 'commodities'
        }
      ]
    }
  ]
}, {
  name: 'News & Analysen',
  id: 'news',
  active: false,
  entries: NewsCategories
}];
