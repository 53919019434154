import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SmartSearchService } from '@solvians-frontend-ivestor/smart-search';
import { EventEmitterData, ResultList, SmartSearchConfig } from '@solvians-frontend-ivestor/smart-search/lib/smart-search.config';
import { ClickedConfig,  } from '../app.config';
import { MainSearchInputConfig } from './main-search-input/main-search-input.config';
import { SearchConfig } from './search.config';
import { SearchService } from './search.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit {
  config: SmartSearchConfig = SearchConfig;
  searchTerm: string;
  notifier: string;
  showSmartSearch: boolean;
  showContent: boolean;
  showBackdrop: boolean;
  showMainSearch: boolean;
  mainSearchInputConfig = MainSearchInputConfig;
  pushUpdate: Subject<ResultList> = new Subject();

  constructor(
    private router: Router,
    private searchService: SearchService,
    private smartSearch: SmartSearchService,
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd && event.url.match('/markets/search')?.length) {
        this.showSmartSearch = true;
      } else if (event instanceof NavigationEnd) {
        this.showSmartSearch = false;
      }
    });
    this.searchService.currentSearch.subscribe((data: string) => {
      if (data === 'derivatives') {
        this.showMainSearch = false;
      } else {
        this.showMainSearch = true;
      }
    });
    this.smartSearch.lastAction.subscribe((data: EventEmitterData) => {
      if (!this.showMainSearch && (data.type === 'setSuggestion' || data.type === 'setExtraSuggestion')) {
        this.router.navigate(['markets', 'search']);
      }
    });
    
  }
  
  onChangedHandler(data: ClickedConfig): void {
    setTimeout(() => {
      switch (data.type) {
        case 'MainSearchInputState':
        case 'MainSearchDropdownState':
        default:
          this.showBackdrop = data.value;
          break;
      }
    })
  }

  onSearchResultClickHandler(event: EventEmitterData): void {
    if (event.type === 'productLink' || event.value?.isin) {
      this.router.navigate(['markets', 'product', 'isin', event.value.isin]);
    }
  }
}
