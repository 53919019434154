<header>
  <button class="close-handler" (click)="cancelHandler()"></button>
  <div class="title">{{data.config.title|translate}}</div>
</header>
<div class="content">
  <ul class="list">
    <li *ngFor="let item of data.tabs" routerLinkActive="active" (click)="changedHandler(item)"
      [ngClass]="[item.entries ? 'has-subnavi': '', item.showSubnavi ? 'selected': '']">
      <a *ngIf="!item.entries" [routerLink]="[item.id]" [queryParams]="item.queryParams">{{item.name | translate}}</a>
      <a *ngIf="item.entries">{{item.name | translate}}</a>
      <ul class="subnavi" *ngIf="item.showSubnavi">
        <li *ngFor="let sub of item.entries" routerLinkActive="active" (click)="changedHandler(sub)">
          <a [routerLink]="[sub.id]" [queryParams]="sub.queryParams">{{sub.name | translate}}</a>
        </li>
      </ul>
    </li>
  </ul>
  <button class="close secondary ghost" (click)="cancelHandler()">{{'close.actionsheet'|translate}}</button>
</div>
