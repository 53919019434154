import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { DemoComponent } from './demo/demo.component';
import { FavoritesComponent } from './tools/favorites/favorites.component';

export const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled'
}

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main-page/main-page.module').then(m => m.MainPageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'markets',
    loadChildren: () => import('./markets/markets.module').then(m => m.MarketsModule),
  },
  {
    path: 'tools',
    loadChildren: () => import('./tools/tools.module').then(m => m.ToolsModule),
  },
  { path: 'demo', component: DemoComponent },
  { path: 'favorites', component: FavoritesComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
