<div class="search-input">
  <div class="backdrop" *ngIf="showBackdrop"></div>
  <app-main-search-input [config]="mainSearchInputConfig" (changed)="onChangedHandler($event)"></app-main-search-input>
  <app-main-search-dropdown *ngIf="showMainSearch" (changed)="onChangedHandler($event)"></app-main-search-dropdown>
  <lib-smart-search [config]="config" *ngIf="!showMainSearch"></lib-smart-search>
</div>
<div class="search-facets">
  <lib-smart-search-facets *ngIf="showSmartSearch"></lib-smart-search-facets>
</div>
<div class="search-content" [hidden]="!showSmartSearch">
  <div class="search-params">
    <lib-smart-search-params></lib-smart-search-params>
  </div>
  <div class="search-result content">
    <div class="content-wrapper">
      <lib-smart-search-result [pushUpdate]="pushUpdate" (clicked)="onSearchResultClickHandler($event)">
      </lib-smart-search-result>
    </div>
  </div>
</div>
