import {
  Underlying
} from 'src/app/app.config';

export interface UnderlyingSelect {
  selectionMax: number;
  alphabet: string[];
  default: UnderlyingSelectItem[];
}
export interface UnderlyingSelectItem {
  label: string;
  values: Underlying[];
}
export const UnderlyingSelectConfig = {
  selectionMax: 5,
  alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
  default: [{
    label: 'Beliebt',
    values: [{
        name: {
          value: 'DAX'
        },
        isin: {
          value: 'DE0008469008'
        },
        wkn: {
          value: '846900'
        },
        currency: {
          value: 'PKT'
        },
        sin: {
          value: '1'
        }
      }, {
        name: {
          value: 'TecDAX'
        },
        isin: {
          value: 'DE0007203275'
        },
        wkn: {
          value: '720327'
        },
        currency: {
          value: 'PKT'
        },
        sin: {
          value: '664076'
        }
      }, {
        name: {
          value: 'MDAX'
        },
        isin: {
          value: 'DE0008467416'
        },
        wkn: {
          value: '846741'
        },
        currency: {
          value: 'PKT'
        },
        sin: {
          value: '500509'
        }
      }, {
        name: {
          value: 'Euro Stoxx 50'
        },
        isin: {
          value: 'EU0009658145'
        },
        wkn: {
          value: '965814'
        },
        currency: {
          value: 'PKT'
        },
        sin: {
          value: '450495'
        }
      }, {
        name: {
          value: 'Dow Jones'
        },
        isin: {
          value: 'US2605661048'
        },
        wkn: {
          value: '969420'
        },
        currency: {
          value: 'PKT'
        },
        sin: {
          value: '526393'
        }
      }, {
        name: {
          value: 'NASDAQ 100'
        },
        isin: {
          value: 'US6311011026'
        },
        wkn: {
          value: 'A0AE1X'
        },
        currency: {
          value: 'PKT'
        },
        sin: {
          value: '526295'
        }
      }, {
        name: {
          value: 'Tesla'
        },
        isin: {
          value: 'US88160R1014'
        },
        wkn: {
          value: 'A1CX3T'
        },
        currency: {
          value: 'USD'
        },
        sin: {
          value: '1265087'
        }
      }, {
        name: {
          value: 'Mercedes-Benz Group'
        },
        isin: {
          value: 'DE0007100000'
        },
        wkn: {
          value: '710000'
        },
        currency: {
          value: 'EUR'
        },
        sin: {
          value: '451493'
        }
      }, {
        name: {
          value: 'Infineon Technologies'
        },
        isin: {
          value: 'DE0006231004'
        },
        wkn: {
          value: '623100'
        },
        currency: {
          value: 'EUR'
        },
        sin: {
          value: '451543'
        }
      }, {
        name: {
          value: 'Deutsche Telekom'
        },
        isin: {
          value: 'DE0005557508'
        },
        wkn: {
          value: '1'
        },
        currency: {
          value: 'EUR'
        },
        sin: {
          value: '451497'
        }
      }, {
        name: {
          value: 'Nokia'
        },
        isin: {
          value: 'FI0009000681'
        },
        wkn: {
          value: '870737'
        },
        currency: {
          value: 'EUR'
        },
        sin: {
          value: '451653'
        }
      }, {
        name: {
          value: 'Gold'
        },
        isin: {
          value: 'XC0009655157'
        },
        wkn: {
          value: '965515'
        },
        currency: {
          value: 'USD'
        },
        sin: {
          value: '1790950'
        }
      }, {
        name: {
          value: 'Silber'
        },
        isin: {
          value: 'XD0002747208'
        },
        wkn: {
          value: '965310'
        },
        currency: {
          value: 'USD'
        },
        sin: {
          value: '653500'
        }
      }, {
        name: {
          value: 'Öl (Brent) Crude Future'
        },
        isin: {
          value: 'XC0009677409'
        },
        wkn: {
          value: '967740'
        },
        currency: {
          value: 'USD'
        },
        sin: {
          value: '451449'
        }
      }, {
        name: {
          value: 'Öl (WTI) Crude Future'
        },
        isin: {
          value: 'XC0007924514'
        },
        wkn: {
          value: '792451'
        },
        currency: {
          value: 'USD'
        },
        sin: {
          value: '451665'
        }
      }
    ]
  }]
};
