import { Component, Input } from '@angular/core';
import { GetTableCSSClass } from 'src/app/helper/helper';
import { ColumnTable } from './column-table.config';

@Component({
  selector: 'app-column-table',
  templateUrl: './column-table.component.html',
  styleUrls: ['./column-table.component.scss']
})
export class ColumnTableComponent {
  @Input() data: any;
  @Input() config: ColumnTable;
  getCSSClass = GetTableCSSClass;
}
