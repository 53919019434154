import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss']
})
export class SelectListComponent {
  @Input() data: any;
  @Input() config: any;
  @Input() clickFunc: any;
  @Output() emitEvent: EventEmitter<any> = new EventEmitter();

  click(data: any) {
    this.emitEvent.emit(data);
  }
}
