import { ChartConfig, ChartDataConfig, ChartSeriesDataConfig, ChartService } from '@solvians-frontend-ninjas/core-charts';
import { CustomChartHelper } from './CustomChartHelper';
import * as Chartist from 'chartist';

export class CustomChartService extends ChartService {

  constructor(apiUrl: string, chartHelper: CustomChartHelper) {
    super(apiUrl, chartHelper);
  }

  setLiveChartData(value: any, type: any): any[] {
    let fillData = [];
    const today = new Date();
    const dataPoint = {
      x: new Date(today.getTime() - 60000),
      y: null,
      v: 0,
      meta: 0,
    };

    if (type === 'step') {
      for (let index = 60; index > 0; index--) {
        fillData.push({
          ...dataPoint,
          x: new Date(today.getTime() - index * 1000),
          y: value.y || null,
          v: value.v || null,
          meta: value.v || null,
        });
      }
    } else {
      fillData = [
        dataPoint,
        {
          ...dataPoint,
          x: new Date(today.getTime() - 1000),
          y: value.y || null,
          v: value.v || null,
          meta: value.v || null
        },
      ];
    }
    return fillData;
  }

  pushLiveChartData = (push: any, series: any, isRelative: boolean, isVolumeChart: any, pushPriceProperty?: PushPriceProperty): void => {
    let price = pushPriceProperty ? push?.fields[pushPriceProperty] : push?.fields?.price;
    let priceRelative = null;
    if (!price?.value || !this.chartHelper.isMatchingDay(new Date(price.time))) {
      price = {
        value: this.getLastDataPoint([series]).y,
        size: this.getLastDataPoint([series]).v,
      };
    }
    if (isRelative && push?.fields?.price?.v) {
      priceRelative = this.calculatePercents(push?.fields?.price?.v, series.baseValue);
    }
    const y = isRelative ? priceRelative : price.value;
    const liveDataPoint = {
      x: new Date(),
      y: isVolumeChart ? price.size : y,
      v: price.size,
      meta: price.size,
    };
    series.data.push(liveDataPoint);
    if (series.data.length > 60) {
      series.data.shift();
    }
  }

  updateLiveChartData = (data: any, chart: any, pushPriceProperty?: PushPriceProperty): void => {
    const isVolumeChart = chart.options.chartType === 'volume';
    const isRelative = chart.options.benchmarks?.length;
    chart.data.series.forEach((series) => {
      let pushData = null;
      Object.keys(data).forEach((isin) => {
        if (data[isin].sin === series.sin.toString()) {
          pushData = data[isin];
        }
      });
      this.pushLiveChartData(pushData, series, isRelative, isVolumeChart, pushPriceProperty);
    });

    const highLow = this.getHighLow(chart.data, chart.options);
    chart.update(
      chart.data, {
        low: highLow.low,
        high: highLow.high,
      },
      true
    );
  }

  override updateData = (data: any, chart: any): void => {
    const identifier = chart.options.identifier
    const price = data[identifier]?.fields?.price
    this.updateDataWithNewPrice(price, chart)
  }

  override formatLabelData(point: any, chart: any, dateTimeFormat: string = 'HH:mm:ss'): any {
    const ohlcFields = ['y', 'h', 'l', 'c'];
    const index = Number(point.getAttribute('ct-point-index'));
    const seriesIndex = point.getAttribute('ct-series-index');
    const data = chart.data.series[seriesIndex]?.data[index];
    const isRelative = chart.options?.resolution;
    if (!data) { return; }
    const currency = chart.options.currency;
    const values = point.getAttribute('ct:value').split(',') || null;
    const volume = point.getAttribute('ct:meta')?.split(',') || null;
    const [timestamp]: [number] = values;
    const valueField = isRelative ? data.r : data?.r || values[1];
    let value = this.chartHelper.FormatValue(valueField, {
      decimalConfig: chart.options.decimalConfig,
    });
    const timestampInSeconds = this.chartHelper.getTimestampInSeconds(timestamp);
    const formattedDateTime: string = this.chartHelper.getFormattedDateTimeFromTimestamp(
      timestampInSeconds,
      dateTimeFormat
    );

    if (chart.options.chartType === 'ohlc' || chart.options.chartType === 'candlestick') {
      value = ohlcFields.map((f: string) =>
        this.chartHelper.FormatValue(data[f], {
          decimalConfig: chart.options.decimalConfig,
        })
      );
    }
    return {
      title: chart.data.series[seriesIndex]?.name,
      currency,
      time: formattedDateTime,
      value,
      volume: this.chartHelper.FormatValue(volume, {
        shortcuts: true,
      })
    };
  }

  getSubscribableInstruments(chartData: ChartDataConfig): ChartSeriesDataConfigCustom[] {
    return chartData.series.filter((series: ChartSeriesDataConfigCustom) => series.priceProperty?.name);  
  }

  getSubscriptions(chartData: ChartDataConfig, pushPriceProperty?: PushPriceProperty): any[] {
    let subscriptions: any[] = [];
    this.getSubscribableInstruments(chartData).forEach((set: ChartSeriesDataConfigCustom) => {
      let priceProperty = pushPriceProperty ? pushPriceProperty : set.priceProperty.name;
      subscriptions = [ ...subscriptions, { 
        price: { 
          subscription: `sin:${set.sin}|id_market:${set.market}|ANY|${priceProperty}|snapshot` 
        }
      }];
    })
    return subscriptions;
  }

  override labelFunc = (point, chart) => {
    const classList = point.parentNode.getAttribute('class').split(' ');
    const data = this.formatLabelData(point, chart);
    let content = '';
    if (classList[2] || !data) {
      return content;
    } else {
      content += `<div class="content-wrapper ${classList[1]}">`;
      content += `<span class="title">${data.title}</span><br>`;
      if (chart.options.chartType === 'ohlc' || chart.options.chartType === 'candlestick') {
        const translations = ['Eröffnung', 'Hoch', 'Tief', 'Schluss', 'Volumen'];
        ['y', 'h', 'l', 'c'].forEach((_v, i) => {
          content += `<span class="label">${translations[i]}:</span>`;
          content += `<span class="value">${data.value[i]} ${data.currency}</span></br>`;
        });
        content += `<span class="label">Volumen:</span>`;
        content += `<span class="value">${data.volume} Stk.</span>`;
      } else {
        content += `<span class="label">Letzter Kurs:</span>`;
        content += `<span class="value">${data.value} ${data.currency}</span></br>`;
        content += `<span class="label">Volumen:</span>`;
        content += `<span class="value">${data.volume} Stk.</span>`;
      }
      content += `</div>`;
      return content;
    }
  };

  calcCustomTicks(chartData: ChartDataConfig, divisor: number): number[] {
    let n = 1;
    const labels = [];
    const start = new Date(chartData.series[0].data[0].x).getTime();
    const end = new Date(chartData.series[0].data[chartData.series[0].data.length-1].x).getTime();
    const hours = Math.floor((end - start) / 3600000);
    labels.push(start);
    while (n < hours) {
      if (n % divisor === 0) { 
        labels.push(start + (n * 3600000));
      }
      n++;
    }
    return labels;
  }

  checkThreshold(config: ChartConfig): boolean { 
    return config?.threshold &&
      config.timespan === '1D' &&
      config.chartType !== 'ohlc' &&
      config.chartType !== 'candlestick' &&
      config.resolution !== 'relative';
  }

  setCustomConfig(config: CustomChartConfig, chartData: ChartDataConfig): ChartConfig {
    config.showArea = this.checkThreshold(config) ? true : config.showArea;
    if (config.axisX) {
      config.axisX.type = this.getAxisType(config);
      config.axisX.labelInterpolationFnc = this.getAxisXLabelFunc(config, chartData.labels?.length);
      config.axisX.ticks = config.timespan === '1D' ? this.calcCustomTicks(chartData, config.axisX.divisor) : null;
    }
    if (config.parent === 'ohlc' && config.chartType === 'volume') {
      config.axisX.type = Chartist.noop();
      chartData.labels = this.getLabelData(chartData);
    }
    return config;
  }
}

export interface CustomChartConfig extends ChartConfig {
  parent?: string;
  pushPriceProperty?: PushPriceProperty;
}
export interface ChartSeriesDataConfigCustom extends ChartSeriesDataConfig {
  market?: number;
  priceProperty?: PriceProperty;
}
export interface PriceProperty {
  id: number;
  name: string;
}
export declare type ChartGraphType = 'line' | 'step' | 'volume' | 'ohlc' | 'candlestick';
export declare type PushPriceProperty = 'bid' | 'ask' | 'last' | 'valuation' | 'price';