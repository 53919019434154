import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-more',
  template: '<a class="button text" [routerLink]="[url]">{{label}}</a>',
  styleUrls: ['./button-more.component.scss']
})
export class ButtonMoreComponent {
  @Input() label: string;
  @Input() url: any;
}
