<div class="content">
  <div class="content-wrapper">
    <app-preloader *ngIf="showPreloader"></app-preloader>
    <app-breadcrumb [data]="breadcrumbConfig"></app-breadcrumb>
    <header class="content-header">
      <h1 class="title">{{'favorites'|translate}}</h1>
    </header>
    <div class="wrapper">
      <div class="view-mode" *ngIf="media === 'regular'">
        <button class="medium ghost-icon default" [ngClass]="{'active': !tilesView}" (click)="tilesView = false"
          aria-label="List-View">
          <span class="icon-list-view"></span>
        </button>
        <button class="medium ghost-icon default" [ngClass]="{'active': tilesView}" (click)="tilesView = true"
          aria-label="Tile-View">
          <span class="icon-tile-view"></span>
        </button>
      </div>
      <p class="error" *ngIf="error">{{error|translate}}</p>
      <div *ngIf="data.length">
        <div *ngIf="media === 'mobile' || tilesView" class="component ghost tiles">
          <app-list-tiles [data]="data" [config]="configTiles" [group]="'marketOverview'">
          </app-list-tiles>
        </div>
        <app-simple-table *ngIf="media !== 'mobile' && !tilesView" [data]="data" [config]="configTable"
          [group]="'marketOverview'">
        </app-simple-table>
      </div>
    </div>
  </div>
</div>
