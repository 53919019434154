import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-tabs-mobile-actionsheet',
  templateUrl: './tabs-mobile-actionsheet.component.html',
  styleUrls: ['./tabs-mobile-actionsheet.scss']
})
export class TabsMobileActionsheetComponent {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomsheet: MatBottomSheetRef<TabsMobileActionsheetComponent>
  ) { }

  cancelHandler(): void {
    this.bottomsheet.dismiss();
  }

  changedHandler(tab: any): void {
    this.toggleSubnavi();
    if (tab.entries) {
      tab.showSubnavi = tab.showSubnavi ? !tab.showSubnavi : true;
      return;
    }
    this.bottomsheet.dismiss(tab);
  }

  toggleSubnavi() {
    const activeSubnavi = this.data.tabs.filter((t: any) => t.showSubnavi);
    if (activeSubnavi.length) { activeSubnavi[0].showSubnavi = false; }
  }
}
