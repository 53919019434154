import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CutWordPipe } from 'src/app/pipes/cutWord/cut-word.pipe';
import { NewsDetail } from '../news.config';
import { NewsTextConfig } from './news-text.config';

@Component({
  selector: 'app-news-text',
  templateUrl: './news-text.component.html',
  styleUrls: ['./news-text.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewsTextComponent implements OnChanges {
  @Input() data: NewsDetail;
  @Input() config?: NewsTextConfig;

  constructor(
    private router: Router,
    private cutWordPipe: CutWordPipe
  ) { }

  ngOnChanges(): void {
    this.data = {
      ...this.data,
      title: this.cutWordPipe.transform(this.data.title, true, this.config?.limitTitle?.toString() || '140', ' ...'),
      body: this.cutWordPipe.transform(this.data.body, true, this.config?.limitBody?.toString() || '300', ' ...')
    };
  }

  navigateTo(id: string) {
    this.router.navigate(['news', 'detail', id]);
  }

}
