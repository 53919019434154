import { Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { TabsItemConfig } from '../tabs/tabs.config';
import { TabsMobileActionsheetComponent } from './tabs-mobile-actionsheet/tabs-mobile-actionsheet.component';

@Component({
  selector: 'app-tabs-mobile',
  templateUrl: './tabs-mobile.component.html',
  styleUrls: ['./tabs-mobile.component.scss']
})
export class TabsMobileComponent implements OnInit {
  @Input() data: Array<TabsItemConfig>;
  @Input() config: any;
  activeTab: TabsItemConfig;
  activeParam: any;
  tabs: Array<TabsItemConfig>;
  dataMobile: Array<TabsItemConfig>;

  constructor(
    private router: Router,
    private bottomSheet: MatBottomSheet
  ) {}

  ngOnInit() {
    this.activeParam = this.router.url.split('/').pop();
    const path = this.data.filter((t: TabsItemConfig) => t.id.indexOf(this.activeParam) !== -1)[0];
    this.activeTab = path || { name: 'menu', active: false, id: null };
    this.dataMobile = this.data.map((t: TabsItemConfig) => {
      return {
        active: t.active,
        id: this.config.mobilePath + t.id.split('/').pop(),
        name: t.name,
        queryParams: t.queryParams,
        entries: t.entries ? t.entries.map((s: TabsItemConfig) => {
          return {
            active: s.active,
            id: this.config.mobilePath + s.id,
            name: s.name,
            queryParams: s.queryParams
          };
        }) : null
      };
    });
  }

  openBottomSheet(): void {
    const bottomSheetRef = this.bottomSheet.open(TabsMobileActionsheetComponent, {
      data: {
        tabs: this.dataMobile,
        config: { title: this.config.title }
      }
    });
    bottomSheetRef.afterDismissed().subscribe((event) => {
      if (!event) { return; }
      else {
        this.activeTab = event;
      }
    });
  }
}
