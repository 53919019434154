<div *ngIf="data && data.length" [ngClass]="group">
  <table aria-describedby="extendedTable" class="sortable">
    <thead>
      <tr>
        <th scope="col" *ngFor="let prop of config.properties">
          <span *ngFor="let child of prop.childs" [ngClass]="getLabelClass(child)"
            (click)="sortTable(child)">{{child.label|translate}}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data">
        <td *ngFor="let prop of config.properties" [ngClass]="getSelectedClass(prop.childs)">
          <span *ngFor="let child of prop.childs" [ngClass]="getCSSClass(child, item[child.id], 0)">
            <app-property-renderer *ngIf="!child.instrumentLink" [data]="item[child.id]" [config]="child" [item]="item">
            </app-property-renderer>
            <a *ngIf="child.instrumentLink" href
              [routerLink]="['/markets/product/isin/', item.isin ? item.isin.value : null]">
              <app-property-renderer [data]="item[child.id]" [config]="child"></app-property-renderer>
            </a>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
