<button *ngIf="config.portfolio" class="round medium menu" [matMenuTriggerFor]="matPortfolio" aria-label="share">
  <span class="icon-portfolio"></span>
</button>
<button class="round medium menu" [matMenuTriggerFor]="menu" aria-label="share">
  <span class="icon-share"></span>
</button>
<mat-menu #menu="matMenu" xPosition="before" class="flyout">
  <button class="share" shareButton="linkedin" [url]="url">
    <span class="icon-linkedin"></span>
  </button>
  <button class="share" shareButton="xing" [url]="url">
    <span class="icon-xing"></span>
  </button>
  <button class="share" shareButton="facebook" [url]="url">
    <span class="icon-facebook"></span>
  </button>
  <button class="share" shareButton="twitter" [url]="url">
    <span class="icon-twitter"></span>
  </button>
</mat-menu>

<mat-menu #matPortfolio="matMenu" xPosition="before" class="flyout functions-list">
  <ul>
    <li>
      <button (click)="onClickHandler()">
        <span class="icon-plus"></span> {{'favorites.add'|translate}}
      </button>
    </li>
  </ul>
</mat-menu>
