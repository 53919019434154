import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatomoModule } from 'ngx-matomo';

import { registerLocaleData, DecimalPipe, DatePipe, PercentPipe } from '@angular/common';
import localeDe from '@angular/common/locales/de';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SearchComponent } from './search/search.component';
import { MainNavigationComponent } from './header/main-navigation/main-navigation.component';
import { MarketsComponent } from './markets/markets.component';
import { MobileNavigationComponent } from './header/mobile-navigation/mobile-navigation.component';
import { MinMaxPipe } from './pipes/minMax/min-max.pipe';
import { ShortNumberPipe } from './pipes/shortNumber/short-number.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloaderModule } from './sharedComponents/preloader/preloader.modules';
import { GraphQLModule } from './graphql.module';
import { SmartSearchModule } from '@solvians-frontend-ivestor/smart-search';
import { CustomChartHelper } from './sharedComponents/chart-lib/CustomChartHelper';
import { environment } from '../environments/environment';
import { CutWordPipe } from './pipes/cutWord/cut-word.pipe';
import { CustomChartService } from './sharedComponents/chart-lib/CustomChartService';
import { SortByPipe } from './pipes/sortBy/sort-by.pipe';
import { PropertyRendererService } from './sharedComponents/property-renderer/property-renderer.service';
import { MainSearchInputComponent } from './search/main-search-input/main-search-input.component'
import { FormsModule } from '@angular/forms';
import { MainSearchDropdownComponent } from './search/main-search-dropdown/main-search-dropdown.component';
import { MainSearchExtendedSearchComponent } from './search/main-search-extended-search/main-search-extended-search.component';
import { ProductSharedModule } from './markets/product/product.shared.module';
import { DebugModule } from '@solvians-frontend-ivestor/debug';
import { PushHelperModule } from '@solvians-frontend-ivestor/push-helper';
import { FavoritesComponent } from './tools/favorites/favorites.component';
import { NotifierComponent } from './sharedComponents/notifier/notifier.component';
import { DateOrTimePipe } from '@solvians-frontend-ivestor/pipes';

registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    HeaderComponent,
    SearchComponent,
    MainNavigationComponent,
    MarketsComponent,
    MobileNavigationComponent,
    MainSearchInputComponent,
    MainSearchDropdownComponent,
    MainSearchExtendedSearchComponent,
    FavoritesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    PreloaderModule,
    GraphQLModule,
    SmartSearchModule,
    FormsModule,
    NotifierComponent,
    FooterComponent,
    ProductSharedModule,
    MatomoModule.forRoot(environment.tracking),
    DebugModule.forRoot(environment.tracing),
    PushHelperModule.forRoot(environment.websockets),
    DateOrTimePipe
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    DecimalPipe,
    PercentPipe,
    DatePipe,
    MinMaxPipe,
    ShortNumberPipe,
    SortByPipe,
    CutWordPipe,
    {
      provide: CustomChartService,
      useValue: new CustomChartService(environment.apiUrl, new CustomChartHelper())
    },
    CustomChartHelper,
    PropertyRendererService,
    DateOrTimePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

// AOT compilation support
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}