import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NavigationConfig, Navigation } from './../main-navigation.config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss']
})
export class MobileNavigationComponent implements OnInit, OnDestroy {
  categories: Array<Navigation> = NavigationConfig;
  mainnaviActive: string;
  @Output() trigger: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) {}

  close(): void {
    this.trigger.emit();
  }

  toggleAll(): void {
    this.categories.forEach((c: any) => {
      c.active = false;
      if (c.entries) {
        c.entries.filter((e: any) => e.active).forEach((i: any) => {
          i.active = false;
        });
      }
    });
  }

  toggleNavi(item: any): void {
    this.categories.forEach((c: any) => {
      c.active = false;
    });
    item.active = !item.active;
  }

  toggleSubNavi(item: any) {
    if (item.active) {
      this.toggleAll();
      item.active = false;
    } else {
      this.toggleAll();
      item.active = true;
    }
  }

  ngOnInit(): void {
    this.mainnaviActive = this.router.url.split('/')[1];
  }

  ngOnDestroy(): void {
    this.toggleAll();
  }
}
