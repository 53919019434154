import { NativeDateAdapter } from '@angular/material/core';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

export const DATE_PICKER_DATE_FORMAT = {
  parse: {
    dateInput: {
      month: 'medium',
      year: 'numeric',
      day: 'numeric'
    }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: {
      year: 'numeric',
      month: 'numeric'
    },
    dateA11yLabel: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    monthYearA11yLabel: {
      year: 'numeric',
      month: 'numeric'
    }
  }
};

@Injectable()
export class DatePickerDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    const dateString = value.split('.');
    const dateFormatted = new Date(`${dateString[2]},${dateString[1]},${dateString[0]}`);
    return dateFormatted ? dateFormatted : null;
  }
  format(date: Date, displayFormat: string): string {
    if (displayFormat === 'input') {
      return formatDate(date, 'dd.MM.yyyy', this.locale);
    } else {
      return formatDate(date, 'MMM yyyy', this.locale);
    }
  }
  getFirstDayOfWeek(): number {
    return 1;
  }
}
