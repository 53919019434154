import { KeyValue } from "@angular/common";

export const getMaxDecimals = (values: Array < any > ): number => {
  const filteredValues = values.filter((item) => item !== 0);
  const decimals = filteredValues.map((item) => {
    if (item.toString().split('.').length !== 2) {
      return 2;
    } else {
      return item.toString().split('.')[1].length;
    }
  });

  return decimals.length ? Math.max(...decimals) : 2;
};

export const objIsEmpty = (obj: {
  hasOwnProperty: (arg0: string) => any;
}) => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const getPriceDecimals = (data: any) => {
  const main = data?.price?.value || data || 0;
  const bid = data?.bid ? data.bid.value || data.bid : 0;
  const ask = data?.ask ? data.ask.value || data.ask : 0;

  return getMaxDecimals([main, bid, ask]);
};

export const GetPushClass = (prev: any, quote: any): string => {
  if (typeof quote !== 'number') { return; }
  let trend: string;
  if (prev < quote) {
    trend = 'up';
  } else if (prev > quote) {
    trend = 'down';
  } else {
    trend = 'neutral';
  }
  return trend;
};

export const CompareValues = (key: string, order = 'asc'): any => {
  return function innerSort(a: any, b: any) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }
    const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : (a[key].value || a[key]);
    const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : (b[key].value || b[key]);
    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
};

export const SortTable = (properties: any, data: any, item: any): any => {
  GetSortDirection(item, properties);
  SortTableData(data, item.id, item.sorting);
  return {
    id: item.id,
    sorting: item.sorting
  };
};

export const SortTableData = (data: any, prop: string, order: string): any => {
  return data.sort(CompareValues(prop, order));
};

export const GetSortDirection = (item: any, properties: any) => {
  if (!item.sorting || item.sorting === 'desc') {
    properties.forEach((p: any) => p.sorting = null);
    item.sorting = 'asc';
  }
  else {
    properties.forEach((p: any) => p.sorting = null);
    item.sorting = 'desc';
  }
  return properties;
};

export const GetPerformance = (close: number, current: number): any => {
  const previousClose = Number(close);
  const currentPrice = Number(current);
  const abs = currentPrice - previousClose;
  return {
    percent: abs * (100 / previousClose),
    value: abs
  };
};

export const ConvertSorting = (data: any) => {
  const prefix = data.sorting === 'desc' ? '-' : '';
  return `${prefix}${data.id}`;
};

export const ReConvertSorting = (data: any) => {
  const params = data.split('-');
  return {
    id: params.length === 1 ? params[0] : params[1],
    sorting: params.length === 1 ? 'asc' : 'desc'
  };
};

export const FormatParam = (param: string, data: any): string => {
  let formattedParam = null;
  switch (param) {
    case 'from_date':
    case 'to_date':
      formattedParam = `${param}=${data.toISOString()}`;
      break;
    case 'sort':
      const prefix = data.sorting === 'desc' ? '-' : '';
      formattedParam = `${param}=${prefix}${data.id}`;
      break;
    default:
      formattedParam = `${param}=${data}`;
      break;
  }
  return formattedParam;
};

export const GetURLParams = (data: any): string => {
  const keys = Object.keys(data);
  let urlParams = `?`;
  keys.forEach((p, i) => {
    if (i && keys.length > i) { urlParams += '&'; }
    urlParams += FormatParam(p, data[p]);
  });
  return urlParams;
};

export const GetTableCSSClass = (property: any, item: any, previousClose?: number): string => {
  if (!item) { return; }
  const propClass = property.label.replace(/[.]/g, '-');
  const cssClass = [propClass];
  if (property.sorting) { cssClass.push('selected'); }

  if (property.id === 'valuationPrice') {
    cssClass.push('trend-' + item.trend);
  } else if (property.calcTrendClass) {
    cssClass.push('trend-' + GetPushClass(previousClose, item[property.src]));
  } else if (property.calcPerformanceClass) {
    cssClass.push('performance-' + GetPushClass(previousClose, item[property.src] || item));
  } else if (property.calcPushClass) {
    cssClass.push('push-' + GetPushClass(previousClose, item[property.src] || item));
  }

  return cssClass.join(' ');
};

export const GetTableLabelCSSClass = (property: any): string => {
  if (!property) { return; }
  const propClass = property.label.replace('.', '-');
  const cssClass = [propClass];
  if (property.sorting) { cssClass.push(property.sorting); }

  return cssClass.join(' ');
};

export const DateMatches = (data: Array<Date> , date: Date): boolean => {
  const matches = data.filter((d: Date) =>
    d.getFullYear() === date.getFullYear() &&
    d.getMonth() === date.getMonth() &&
    d.getDate() === date.getDate());
  return matches.length ? true : false;
};

export const SetupSorting = (properties: any, sorting: any): void => {
  if (!sorting) { return; }
  const prop = properties.filter((p: any) => p.childs.filter((c: any) => c.id === sorting.id).length);
  if (!prop.length) { return; }
  const child = prop[0].childs.filter((c: any) => c.id === sorting.id)[0];
  child.sorting = sorting.sorting;
};

export const produce = (data: any): any => {
  return JSON.parse(JSON.stringify(data));
}

export const OriginalOrder = (_a: KeyValue <number, string> , _b: KeyValue <number, string> ): number => {
  return 0;
}

export const ScrollTop = (): void => {
  window.scrollTo({
    top: 0,
    left: 0
  });
}