import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Instrument } from '../services/instrument-service/instrument.config';
import { PushData, PushDataFieldTypes } from './push.config';
import { PushHelperService } from '@solvians-frontend-ivestor/push-helper';

@Injectable({
  providedIn: 'root'
})
export class PushService {
  readonly DATA = new BehaviorSubject([]);
  readonly data$ = this.DATA.asObservable();
  worker: Worker;

  constructor(
    private pushHelperService: PushHelperService
  ) {}

  start(): void {
    const $this = this;
    this.worker = new Worker(new URL('./push.worker', import .meta.url), {
      type: 'module'
    });
    this.worker.onmessage = (e: MessageEvent) => {
      if (e.data.error) { return; }
      $this.update(e.data);
    };
    this.worker.postMessage({
      type: 'subscription',
      value: this.pushHelperService.getSubscription()
    });
  }

  add(data: Instrument[]): void {
    if (!this.worker) { return; }
    data.forEach((instrument: Instrument) => {
      if (instrument?.price?.subscription) { return }
      instrument.price.subscription = this.updatePriceSubscriptions(instrument.price.subscription)
    });
    this.worker.postMessage(data);
  }

  update(data: Array<PushData>): void {
    const $this = this;
    const mapFields = this.mapFields.bind(this);
    data.forEach((p: PushData) => {
      const mappedData = mapFields(p);
      if (!mappedData) { return; }
      $this.DATA.next(mapFields(p));
    });
  }

  getFieldType(field: string): PushDataFieldTypes {
    const isBidAsk = field === 'bid' || field === 'ask';
    return isBidAsk ? field : 'price';
  }

  mapFields(pushData: PushData): any {
    if (!pushData.fields) { return; }
    const quotes: any = {};
    let nullValue: boolean = false;

    for (const f in pushData.fields) {
      if (pushData.fields.hasOwnProperty(f)) {
        const field = pushData.fields[f];
        if (field.v === '0.0') {
          nullValue = true;
        }
        quotes[this.getFieldType(f)] = {
          value: Number(field.v),
          time: field.t,
          size: field.s,
        };
      }
    }
    
    return nullValue ? null : {
      [pushData.id]: {
        fields: quotes,
        marketId: pushData.marketid,
        sin: pushData.sin,
      }
    };
  }

  updatePriceSubscriptions(subscription: string): string {
    let subscriptionStr: string;
    if (subscription.match('bid')) {
      subscriptionStr = subscription.replace('|snapshot', ',ask|snapshot');
    } else if (subscription.match('ask')) {
      subscriptionStr = subscription.replace('|snapshot', ',bid|snapshot');
    } else {
      subscriptionStr = subscription.replace('|snapshot', ',bid,ask|snapshot');
    }
    return subscriptionStr;
  }
}
