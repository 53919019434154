<div class="wrapper" (mouseover)="stopTimer = true" (mouseleave)="stopTimer = false" [ngClass]="group">
    <button class="switch-button next" (click)="switch(1)" aria-label="switch"></button>
    <button class="switch-button prev" (click)="switch(0)" aria-label="switch"></button>
  <div class="indicator">
    <button *ngFor="let item of data; let i = index;" class="dot" [ngClass]="{ 'active': i === isOpen}"
      (click)="toggle(i, true)" aria-label="switch"></button>
  </div>
  <ul class="slider">
    <li [@openClose]="isOpen === i ? 'open' : 'closed'" *ngFor="let item of data; let i = index;" class="slide" [ngClass]="{ 'active': i === isOpen}">
      <img [attr.src]="item.img" alt="" />
      <div class="content">
        <div class="category">{{item.category|translate}}</div>
        <div class="source">{{item.firstCreated|date:'dd.MM.yyy / HH:mm:ss'}} ({{item.source}})</div>
        <div class="title">{{item.title|cutWord:true:titleLength:' ...'}}</div>
        <button class="primary green" (click)="navigateTo(item.id)">{{'read.more'|translate}}</button>
      </div>
    </li>
  </ul>
</div>