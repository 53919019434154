import { Component, Injectable, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

export const PICK_FORMATS = {
  parse: {
    dateInput: {
      month: 'short',
      year: 'numeric',
      day: 'numeric'
    }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: {
      year: 'numeric',
      month: 'short',
    },
    dateA11yLabel: {
      year: 'numeric',
      month: 'long',
      day: 'dd'
    },
    monthYearA11yLabel: {
      year: 'numeric',
      month: 'long'
    }
  }
};

@Injectable()
class PickDateAdapter extends NativeDateAdapter {
  format(date: Date): string {
    return formatDate(date, 'MMM yyyy', this.locale);
  }
}

@Component({
  selector: 'app-select-month',
  templateUrl: './select-month.component.html',
  providers: [
    { provide: DateAdapter, useClass: PickDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }
  ]
})
export class SelectMonthComponent {
  @Output() trigger: EventEmitter<any> = new EventEmitter();
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @ViewChild('dp') dp: MatDatepicker<Date>;
  date = new FormControl(new Date());

  chosenYearHandler(date: any) {
    const ctrlValue = this.date.value;
    ctrlValue.setFullYear(date.getFullYear());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(date: any, datepicker: any) {
    const ctrlValue = this.date.value;
    ctrlValue.setMonth(date.getMonth());
    ctrlValue.setDate(1);
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.trigger.emit(ctrlValue);
  }
}
