import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error',
  template: '<p class="error" *ngIf="data">{{data|translate}}</p>',
  styleUrls: ['../../../scss/error.scss']
})
export class ErrorComponent {
  @Input() data: string;
}
