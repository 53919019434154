  <footer>
    <ul>
      <li>© {{year}} <span class="icon-ivestor-logo-inverted"><span class="path1"></span><span
            class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span
            class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span
            class="path10"></span><span class="path11"></span><span class="path12"></span></span></li>
      <li><a routerLink="/imprint" routerLinkActive="active" title="imprint">Impressum</a></li>
      <li><a routerLink="/legal" routerLinkActive="active" title="legal">Nutzungsbedingungen</a></li>
      <li><a routerLink="/privacy" routerLinkActive="active" title="privace">Datenschutz</a></li>
      <li class="social">
        Follow us:
        <a href="https://de.linkedin.com/company/solvians-it-solutions-gmbh" class="icon-linkedin"
          aria-label="link linkedin"></a>
        <a href="https://www.xing.com/companies/solviansit-solutionsgmbh" class="icon-xing" aria-label="link xing"></a>
        <a href="https://twitter.com/Ivestor_de" class="icon-twitter" aria-label="link twitter"></a>
        <a href="https://www.facebook.com/ivestor.portal/" class="icon-facebook" aria-label="link facebook"></a>
      </li>
    </ul>
  </footer>
