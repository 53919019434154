import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabsConfig, TabsItemConfig } from './tabs-static.config';

@Component({
  selector: 'app-tabs-static',
  templateUrl: './tabs-static.component.html',
  styleUrls: ['./tabs-static.component.scss']
})
export class TabsStaticComponent implements OnInit {
  @Input() data: Array<TabsItemConfig>;
  @Input() config: TabsConfig;
  @Output() clicked: EventEmitter<any> = new EventEmitter();
  styles: any;

  ngOnInit() {
    this.styles = this.setCSSClasses();
  }

  setCSSClasses(): string {
    return `${this.config?.subnavi ? 'subnavi' : ''} ${this.config?.style ? this.config.style : ''}`;
  }

  click(data: any) {
    const activeTab = this.data.filter((t: any) => t.active);
    if (activeTab.length) { activeTab[0].active = false; }
    data.active = true;
    this.clicked.emit(data);
  }
}
