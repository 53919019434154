import { Injectable } from '@angular/core';
import { generateNewsPictureUrl, NewsDTO, WidthSize } from '@solvians-frontend-ninjas/news-api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  imageSize: BehaviorSubject<number[]> = new BehaviorSubject([]);

  private getImageWidth(media: string, fullSize?: boolean): WidthSize {
    let imageSize: WidthSize;
    switch (media) {
      case 'mobile':
        imageSize = 546;
        break;
      case 'small':
      case 'medium':
        imageSize = fullSize ? 840 : 368;
        break;
      default:
        imageSize = fullSize ? 1280 : 368;
        break;
    }
    return imageSize;
  }

  getImage(data: NewsDTO, media: string, fullSize?: boolean): string {
    return generateNewsPictureUrl(data, {
      host: 'www.eniteo.de',
      width: this.getImageWidth(media, fullSize),
      baseUrl: 'https://tag.imagesnews.com/deliver/deliver.php?data='
    });
  }
}
