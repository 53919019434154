import {
  Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'cutWord'
})
export class CutWordPipe implements PipeTransform {
  transform(value: string, wordwise: boolean, max: string, tail: string): string {
    if (!value) {
      return '';
    }

    const maximum = parseInt(max, 10);
    if (value.length <= maximum) {
      return value;
    }

    let text = value.substring(0, maximum);
    if (wordwise) {
      let lastspace = text.lastIndexOf(' ');
      if (lastspace !== -1) {

        // Also remove . and , so it gives a cleaner result.
        if (text.charAt(lastspace - 1) === '.' || text.charAt(lastspace - 1) === ',') {
          lastspace = lastspace - 1;
        }

        text = value.substring(0, lastspace);
      }
    }

    return text + (tail || ' ...');
  }
}
