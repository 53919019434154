import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Instrument } from 'src/app/services/instrument-service/instrument.config';
import { FavoritesService } from 'src/app/tools/favorites/favorites.service';

@Component({
  selector: 'app-function-bar',
  templateUrl: './function-bar.component.html',
  styleUrls: ['./function-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FunctionBarComponent implements OnInit {
  @Input() config: any = {};
  @Input() data: Instrument;
  url: string;

  constructor(
    private favoritesService: FavoritesService
  ) {}

  ngOnInit(): void {
    this.url = location.href;
  }

  onClickHandler():void {
    this.favoritesService.addInstrument(this.data);
  }

}
