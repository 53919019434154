import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Instrument, InstrumentStoreConfig } from './instrument.config';

@Injectable({
  providedIn: 'root'
})

export class InstrumentStoreService {
  private readonly IDENTIFIER = new BehaviorSubject({});
  private readonly DATA = new BehaviorSubject<Instrument>(null);
  private readonly CONFIG = new BehaviorSubject<InstrumentStoreConfig>(null);
  readonly identifier$ = this.IDENTIFIER.asObservable();
  readonly data$ = this.DATA.asObservable();
  readonly config$ = this.CONFIG;

  set data(val: any) {
    this.DATA.next(val);
    this.IDENTIFIER.next({ isin: val.isin.value });
  }
  set config(val: any) {
    this.CONFIG.next(val);
  }

  setData(data: any, config: any): void {
    this.data = data;
    this.config = config;
  }

  updateData(data: any): void {
    this.data = { ...this.DATA.getValue(), ...data };
  }
}
