<div class="component" [ngClass]="group">
  <h2 *ngIf="config?.title">{{config.title|translate}}</h2>

  <table aria-describedby="" [ngClass]="{'shrinked': config?.shrinked}">
    <thead>
      <tr>
        <th scope="col" *ngFor="let item of config?.properties" [ngClass]="item.label" [attr.colspan]="item.colspan">
          {{item.label|translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data">
        <td *ngFor="let prop of config?.properties" [ngClass]="getCSSClass(prop, item[prop.id], 0)">
          <span *ngIf="prop.type === 'chart' && item.sin" class="chart">
            <app-lib-chart [identifier]="{ name: 'sin', value: item.sin.value }" [config]="chartConfig">
            </app-lib-chart>
          </span>
          <app-property-renderer *ngIf="!prop.instrumentLink && prop.type !== 'chart'" [data]="item[prop.id]"
            [config]="prop" [item]="item">
          </app-property-renderer>
          <a *ngIf="prop.instrumentLink && prop.type !== 'chart'" href
            [routerLink]="['/markets/product/isin/', item.isin ? item.isin.value : null]">
            <app-property-renderer [data]="item[prop.id]" [config]="prop"></app-property-renderer>
          </a>
        </td>
        <td *ngIf="config.menu" class="menu">
          <app-flyout-table-row [config]="config.menu" [data]="item"></app-flyout-table-row>
        </td>
      </tr>
    </tbody>
  </table>

  <p class="more" *ngIf="config?.moreText">
    <app-button-more [label]="config.moreText|translate" [url]="config.showMore"></app-button-more>
  </p>
</div>
