import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, RouterEvent } from '@angular/router';
import { Location } from '@angular/common';
import { TranslatedURL, URLConfig } from './header/main-navigation.config';
import { MediaQueryService } from './services/mediaquery-service/mediaquery-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showPreloader = false;

  constructor(
    private router: Router,
    private location: Location,
    private mediaQueryService: MediaQueryService,
    private renderer: Renderer2,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit():void {
    this.mediaQueryService.init();
    this.setURL();
  }

  getURL(url: string): TranslatedURL {
    const rawURL = url.split('?');
    const matchingURL: TranslatedURL = {
      translated: false,
      realPath: rawURL[0],
      params: rawURL[1],
      translatedPath: null
    };

    URLConfig.every((data: any) => {
      const urlKey = Object.keys(data)[0];
      if (urlKey === rawURL[0]) {
        matchingURL.translatedPath = data[urlKey][this.locale];
        return false;
      } else if (data[urlKey][this.locale] === rawURL[0]) {
        matchingURL.translated = true;
        matchingURL.realPath = urlKey;
        matchingURL.translatedPath = data[urlKey][this.locale];
        return false;
      } else {
        return true;
      }
    });
    return matchingURL;
  }

  setURL(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.showPreloader = true;
          this.onNavigationStart(event)
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel: {
          const translatedURL = this.getURL(this.router.url);
          if (translatedURL.params) {
            this.location.replaceState(translatedURL.translatedPath || translatedURL.realPath);
          }
          else if (translatedURL.translated) {
            this.router.navigateByUrl(translatedURL.realPath, { skipLocationChange: true });
          } else {
            this.location.replaceState(translatedURL.translatedPath || translatedURL.realPath);
            this.router.navigateByUrl(translatedURL.realPath, { skipLocationChange: true });
          }
          this.showPreloader = false;
          break;
        }
        case event instanceof NavigationError: {
          this.showPreloader = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  onNavigationStart(event: RouterEvent): void {
    switch (event.url) {
      case '/imprint':
      case '/impressum':
      case '/privacy':
      case '/legal':
        this.renderer.addClass(document.body, 'bg-custom');
        break;
      default:
        this.renderer.removeClass(document.body, 'bg-custom');
        break;
    }
  }
}
