export interface TableConfig {
  properties: TableConfigPropertyChilds[];
  tableSorting?: any;
  menu?: any;
}

export interface TableConfigPropertyChilds {
  childs: TableConfigProperty[];
}

export interface TableConfigProperty {
  label?: string;
  id?: string;
  type: 'volume' | 'number' | 'price' | 'productLevel' | 'text' | 'date' | 'dateOrTime' |
    'maturity' | 'percents' | 'performance' | 'market' | 'label' | 'chart' | 'default';
  src?: string;
  instrumentLink?: boolean;
  decimalConfig?: string;
  format?: string;
  suffix?: string;
  calcPerformanceClass?: boolean;
  calcTrendClass?: boolean;
  calcPushClass?: boolean;
  url?: string;
  currency?: boolean;
  colspan?: number;
}

export const createExtendedTableConfig = (config: TableConfig): TableConfig => {
  return {
    tableSorting: config.tableSorting,
    properties: config.properties
  };
};
