import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Chartist from 'chartist';
import * as ToolTipDonut from './chart-plugin-tooltip-donut';
import { DonutChartConfig, DonutChartConfigDefault } from './donut-chart.config';

@Component({
  selector: 'app-donut-chart',
  template: '<div *ngIf="error" class="error">{{error|translate}}</div><div class="ct-donut-chart" #donutChart></div>',
  styleUrls: ['./donut-chart.component.scss']
})

export class DonutChartComponent implements OnInit {
  @Input() data: any;
  @Input() config: DonutChartConfig;
  @ViewChild('donutChart') donutChart: ElementRef;
  error: string;
  chart: any;

  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {
    this.triggerSearch = this.triggerSearch.bind(this);
    if (!this.data || !this.data.values.length) {
      this.error = 'error.standard';
      return;
    }
    setTimeout(() => {
      this.drawChart(this.data);
    });
  }
  
  triggerSearch(tooltip: any, options: any): void {
    if (!this.config.targetUrl) { return }
    const searchterm = options.data.values[tooltip.index][this.config.targetValue];
    this.router.navigate([`${this.config.targetUrl}/${searchterm}`]);
  }

  drawChart(data: any): void {
    const config = this.getConfig(data);
    const chartData = this.getData(data.values);
    this.chart = new Chartist.Pie(this.donutChart.nativeElement, chartData, config);
  }

  getConfig(data: any): any {
    const config = { ...DonutChartConfigDefault, ...this.config };
    config.plugins = [
      ToolTipDonut({
        offsetX: 10,
        offsetY: 64,
        clickFunc: this.triggerSearch,
        suffix: ` ${config.suffix}`,
        data: this.data
      })
    ];
    config.total = data.count;
    return config;
  }

  getData(values: Array<any> ): any {
    const data = {
      series: [],
      labels: []
    };
    values.forEach((i) => {
      data.series.push(i.count);
      data.labels.push(i.label);
    });
    return data;
  }
}
