<div class="main-search-dropdown" [ngClass]="{'detail': media !== 'mobile' && dataDetail}" *ngIf="active">
  <div class="main-search-dropdown-content">
    <ul class="main-search-dropdown-list">
      <li *ngFor="let item of data | keyvalue: sort">
        <h5>{{item.key|translate}}</h5>
        <ul>
          <li *ngFor="let value of item.value.data" [attr.class]="item.key" [ngClass]="{'selected': value.selected }"
            [innerHTML]="value.label" (mouseenter)="onMouseEnterHandlerSearch({type: item.key, value}, $event)"
            (click)="onClickHandlerSearch({type: item.key, value})"></li>
        </ul>
      </li>
    </ul>
  </div>
  <div class="main-search-dropdown-detail" *ngIf="media !== 'mobile' && dataDetail">
    <ul>
      <li *ngIf="displayType === 'search'">
        <span class="type" [ngClass]="dataDetail.type">{{dataDetail.type|translate}}</span>
        <app-list-tiles [data]="[dataDetail.value.detail]" [config]="configTiles" [group]="'mainSearchDropdown'">
        </app-list-tiles>
        <button class="primary green"
          (click)="onClickHandlerSearch({type: 'stocks', value: dataDetail.value})">{{'goto.detailpage'|translate}}</button>
      </li>
      <li *ngIf="dataDetail.type === 'news'">
        <app-news-text [config]="{ body: true, limitBody: 500 }" [data]="dataDetail.value?.detail"></app-news-text>
      </li>
      <li *ngIf="dataDetail.type === 'extended'">
        <span class="type" [ngClass]="dataDetail.label">{{dataDetail.label|translate}}</span>
        <app-main-search-extended-search [data]="dataDetail"></app-main-search-extended-search>
      </li>
    </ul>
  </div>
</div>
