import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MediaQueryService } from 'src/app/services/mediaquery-service/mediaquery-service.service';
import { ImageService } from 'src/app/services/image-service/image-service.service';
import { MediaQueries } from 'src/app/services/mediaquery-service/mediaquery-service.config';
import { NewsDetail } from 'src/app/news/news.config';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss', './image-slider.group.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
      })),
      state('closed', style({
        opacity: 0,
      })),
      transition('open => closed', [
        animate('300ms')
      ]),
      transition('closed => open', [
        animate('300ms')
      ]),
    ]),
  ],
})

export class ImageSliderComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<any> = new Subject<any>();
  @Input() data: any;
  @Input() group: any;
  isOpen = 0;
  stopTimer: boolean;
  titleLength: string;

  constructor(
    private router: Router,
    private mediaQueryService: MediaQueryService,
    private imageService: ImageService
  ) {}

  switch(direction: number): void {
    const count = this.isOpen;
    this.isOpen = direction ? count - 1 : count + 1;
    if (this.isOpen > 4) { this.isOpen = 0; }
    else if (this.isOpen < 0) { this.isOpen = 4; }
  }

  toggle(index: number, click?: boolean): void {
    if (!click && this.stopTimer) { return; }
    this.isOpen = index;
  }

  ngOnInit(): void {
    this.mediaQueryService.MEDIA.subscribe((media: MediaQueries) => {
      setTimeout(() => {
        switch (media) {
          case 'regular':
            this.titleLength = '65';
            break;
          case 'mobile':
            this.titleLength = '90';
            break;
          default:
            this.titleLength = '50';
            break;
        }
        this.data.forEach((d: NewsDetail) => {
          d.img = this.imageService.getImage(d, media, true);
        });
      }, 1);
    });
    this.setTimer();
  }

  setTimer(): void {
    const numbers = timer(5000, 5000);
    numbers
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.toggle(this.isOpen < 4 ? this.isOpen + 1 : 0));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  navigateTo(id: string): void {
    this.router.navigate(['news', 'detail', id]);
  }

}
