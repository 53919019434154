import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data-service/data.service';
import { MediaQueryService } from 'src/app/services/mediaquery-service/mediaquery-service.service';
import { TabsEntryConfig } from 'src/app/sharedComponents/tabs-static/tabs-static.config';

@Component({
  selector: 'app-market-overview',
  templateUrl: './market-overview.component.html',
  styleUrls: ['./market-overview.component.scss']
})
export class MarketOverviewComponent implements OnInit {
  @Input() config: any;
  data: any;
  error: string;
  showPreloader: boolean;
  media: string;
  index: any;
  tilesView = true;
  configTable: any;
  configTiles: any;

  constructor(
    private dataService: DataService,
    private mediaQueryService: MediaQueryService,
  ) { }

  ngOnInit(): void {
    this.mediaQueryService.MEDIA.subscribe((media: string) => {
      this.media = media;
    });
    this.index = this.config.tabs.filter((t: any) => t.active)[0];
    this.getData(this.index);
  }

  getData(index: any): void {
    this.showPreloader = true;
    this.dataService.getData(
      null, null, 'instrument/lists', null, index.id, this.config.urlQuery).subscribe({
      next: (data: any) => this.renderData(data),
      error: (error: string) => this.showError(error)
    });
  }

  tabsClicked(data: TabsEntryConfig): void {
    this.index = data;
    this.getData(data);
  }

  renderData(response: any): void {
    this.data = response.instruments;
    this.configTable = this.config.table;
    this.configTiles = this.config.tiles;
    this.showPreloader = false;
  }

  showError(error: any): void {
    this.data = null;
    this.error = error;
    this.showPreloader = false;
  }
}
