import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Apollo, gql} from 'apollo-angular';
import NewsMapping from '../../data/news.mapping.json';
import { NewsDetail } from 'src/app/news/news.config';

@Injectable({
  providedIn: 'root'
})
export class NewsDataService {
  readonly api = environment.apiNews;
  listNewsQuery = gql`
    query GET_ALL_NEWS(
      $title: String,
      $subjects: [String],
      $countries: [String],
      $page: Int,
      $rowsPerPage: Int,
      $identifiers: String,
      $startDate: Long,
      $endDate: Long
    ) {
      searchNews(
        input: {
          title: { contains: $title }
          subjects: { in: $subjects }
          countries: { in: $countries }
          identifiers: { eq: $identifiers }
          firstCreated: { gte: $startDate, lte: $endDate }
        }
        siteVariant: "${this.api.siteVariant}"
        page: $page
        rowsPerPage: $rowsPerPage
      ) {
        newsDTOList {
          title
          subjects
          countries
          source
          id
          firstCreated
          body
        }
      }
    }
  `;

  constructor(
    private http: HttpClient,
    private apollo: Apollo
  ) { }

  getData(id: string): Observable<NewsDetail[]> {
    return this.http.get(`${this.api.service}/${id}/${this.api.siteVariant}`).pipe(
      retry(2),
      timeout(5000),
      catchError(this.handleError)
    ).pipe(
      map((result: any) => this.mapData([result]))
    );
  }

  getDataFromGQL(params: any): Observable<[NewsDetail]> {
    return this.apollo.watchQuery({
      query: this.listNewsQuery,
      variables: params
    }).valueChanges.pipe(
      map((result: any) => this.mapData(result.data.searchNews.newsDTOList))
    );
  }

  getCategoriesBySubjects(subjects: string[]): string[] {
    const categories = [];
    subjects.forEach((s: string) => {
      Object.values(NewsMapping.categories)?.forEach((c: any, i: number) => {
        if (c?.indexOf(s) > -1) {
          categories.push(Object.keys(NewsMapping.categories)[i]);
        }
      });
    });
    return categories;
  }

  getSubjectsByType(type: string): string[] {
    let subjects: string[];
    if (NewsMapping.categories[type]) {
      subjects = NewsMapping.categories[type].split(', ');
    } else {
      subjects = [];
    }
    return subjects;
  }

  getCountriesByType(type: string): string[] {
    return NewsMapping.types[type].split(', ');
  }

  mapData(data: any): any {
    return data.map((d: NewsDetail) => {
      return {
        ...d,
        category: this.getCategoriesBySubjects(d?.subjects)
      };
    });
  }

  mapToSingleCategory(data: NewsDetail[]): NewsDetail[] {
    return data.map((d: NewsDetail) => {
      return {
        ...d,
        category: d.category[0]
      };
    });
  }

  removeDoubles(data: NewsDetail[]): NewsDetail[] {
    return data.reduce((acc: any, current: any) => {
      const x = acc.find(item => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}`);
    }
    return throwError(error.error.message || 'error.standard');
  }
}
