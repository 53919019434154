import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-filters-date-mobile',
  templateUrl: './filters-date-mobile.component.html'
})
export class FiltersDateMobileComponent {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomsheet: MatBottomSheetRef<FiltersDateMobileComponent>
  ) { }

  submitHandler(): void {
    this.bottomsheet.dismiss('submit');
  }
  cancelHandler(): void {
    this.bottomsheet.dismiss();
  }

}
