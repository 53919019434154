import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ChartConfig, ChartThemeConfig } from '@solvians-frontend-ninjas/core-charts';
import { GetTableCSSClass } from '../../helper/helper';
import { MiniChartConfig } from '../chart-lib/chart-lib.config';
import { SimpleTable } from './simple-table.config';

@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimpleTableComponent implements OnInit {
  @Input() data: any;
  @Input() config: SimpleTable;
  @Input() group?: string;
  chartConfig: ChartConfig;
  getCSSClass = GetTableCSSClass;

  ngOnInit(): void {
    ChartThemeConfig.mini = MiniChartConfig;
    this.chartConfig = { theme: 'mini' };
  }
}
