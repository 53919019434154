<header class="content-header">
  <h1>{{config.title | translate}}</h1>
</header>
<div class="wrapper">
  <app-tabs-static [data]="config.tabs" [config]="{ subnavi: true, style: 'large' }" (clicked)="tabsClicked($event)">
  </app-tabs-static>
  <app-preloader *ngIf="showPreloader" config="with-navigation"></app-preloader>
  <div class="view-mode" *ngIf="media === 'regular'">
    <button class="medium ghost-icon default" [ngClass]="{'active': !tilesView}" (click)="tilesView = false" aria-label="List-View">
      <span class="icon-list-view"></span>
    </button>
    <button class="medium ghost-icon default" [ngClass]="{'active': tilesView}" (click)="tilesView = true" aria-label="Tile-View">
      <span class="icon-tile-view"></span>
    </button>
  </div>
  <p class="error" *ngIf="error">{{error|translate}}</p>
  <div *ngIf="data" class="content-wrapper">
    <div *ngIf="media === 'mobile' || tilesView" class="component ghost tiles">
      <app-list-tiles [data]="data" [config]="configTiles" [group]="'marketOverview'">
      </app-list-tiles>
    </div>
    <app-simple-table *ngIf="media !== 'mobile' && !tilesView" [data]="data" [config]="configTable" [group]="'marketOverview'">
    </app-simple-table>
  </div>
</div>
