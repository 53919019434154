<ul *ngIf="data && data.length" class="tiles" [ngClass]="group">
  <li *ngFor="let item of data">
    <div *ngFor="let prop of config.properties" class="tiles-wrapper">
      <span *ngFor="let child of prop.childs" [ngClass]="getCSSClass(child, item[child.id], 0)" class="tiles-content">
        <span *ngIf="child.type === 'chart' && item.sin" class="chart">
          <app-lib-chart class="chart" [identifier]="{ name: 'sin', value: item.sin.value }" [config]="chartConfig">
          </app-lib-chart>
        </span>
        <app-property-renderer *ngIf="!child.instrumentLink && child.type !== 'chart'" [data]="item[child.id]"
          [config]="child" [item]="item">
        </app-property-renderer>
        <a *ngIf="child.instrumentLink && child.type !== 'chart'" href
          [routerLink]="['/markets/product/isin/', item.isin ? item.isin.value : null]">
          <app-property-renderer [data]="item[child.id]" [config]="child"></app-property-renderer>
        </a>
      </span>
    </div>
    <div *ngIf="config.menu" class="menu">
      <app-flyout-table-row [config]="config.menu" [data]="item"></app-flyout-table-row>
    </div>
  </li>
</ul>