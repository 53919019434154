import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FiltersConfigData } from '../filters/filters.config';

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
})
export class FilterInputComponent {
  @Input() data: FiltersConfigData;
  @Output() keyUp: EventEmitter<any> = new EventEmitter();
  @Output() focussed: EventEmitter<any> = new EventEmitter();

  keyUpHandler(data: any): void {
    this.keyUp.emit(data);
  }

  focusHandler(data: any): void {
    this.focussed.emit(data);
  }
}
