import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-preloader',
  template: '<div class="preloader" [ngClass]="config"><span class="icon-ive"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span><div class="loader"></div></div>',
  styleUrls: ['./preloader.component.scss']
})

export class PreloaderComponent {
  @Input() config: string;
}
