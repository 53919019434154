import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { retry, timeout } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { GetURLParams } from '../../helper/helper';
import { InstrumentService } from '../../services/instrument-service/instrument.service';
import { InstrumentStoreService } from '../../services/instrument-service/instrument-store.service';
import { DebugService } from '@solvians-frontend-ivestor/debug';
import { InstrumentApi } from '../instrument-service/instrument.config';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http: HttpClient,
    private instrumentStoreService: InstrumentStoreService,
    private instrumentService: InstrumentService,
    private debug: DebugService
  ) { }

  getData(
    identifier: string,
    identifierValue: string,
    api: string,
    marketId?: any,
    subPath?: string,
    data?: any,
  ): Subject<Object> {
    const returnData: Subject<Object> = new Subject();   
    const url = this.getUrl({
      identifier,
      identifierValue,
      api,
      marketId,
      subPath,
      data
    });

    this.http.get<Object>(url, { observe: "response" })
      .pipe(
        retry(2),
        timeout(9000)
      )
      .subscribe({
        next: (response: any) => {
          this.debug.set(response);
          returnData.next(response.body);
        },
        error: () => returnData.error('error.standard')
      });
      return returnData;
  }

  getIdentifier(): any {
    return this.instrumentStoreService.identifier$;
  }

  getUrl(props: InstrumentApi): string {
    let url = `${environment.apiUrl}/${props.api}`;
    url += props.identifierValue ? `/${props.identifier}/${props.identifierValue}` : '';
    url += props.marketId ? '/market/' + props.marketId : '';
    url += props.subPath ? '/' + props.subPath : '';
    url += props.data ? '/' + GetURLParams(props.data) : '';
    return url;
  }

  store(data: any): void {
    this.instrumentStoreService.setData(
      data,
      this.instrumentService.getConfigQuotes(data)
    );
  }
}
