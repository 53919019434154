<p class="error" *ngIf="error">{{error|translate}}</p>
<app-preloader *ngIf="showPreloader"></app-preloader>
<div *ngIf="!error">
  <app-image-slider *ngIf="sliderData" [data]="sliderData"></app-image-slider>
  <ul class="preview-tiles">
    <li *ngFor="let item of latest">
      <app-news-preview [data]="item"></app-news-preview>
    </li>
  </ul>
  <ul class="types">
    <li *ngFor="let type of types">
      <h1 *ngIf="type.data?.length">{{type.name|translate}}</h1>
      <ul class="preview-tiles">
        <li *ngFor="let item of type.data | slice:0:viewConfig.previewTiles">
          <app-news-preview [data]="item"></app-news-preview>
        </li>
      </ul>
      <ul class="text-tiles">
        <li *ngFor="let item of type.data | slice:viewConfig.previewTiles:viewConfig.previewTiles + viewConfig.textTiles">
          <app-news-text [data]="item"></app-news-text>
        </li>
      </ul>
    </li>
  </ul>
</div>
