import { Component, Input, OnChanges } from '@angular/core';
import { GetPerformance } from 'src/app/helper/helper';

@Component({
  selector: 'app-performance-renderer',
  templateUrl: './performance-renderer.component.html'
})
export class PerformanceRendererComponent implements OnChanges {
  @Input() data: any;
  @Input() prevClose: any;
  @Input() config: any;
  @Input() type: string;
  value: any;

  ngOnChanges(): void {
    if (!this.prevClose) { return; }
    this.value = GetPerformance(this.prevClose.value, this.data.value)[this.type];
  }
}
