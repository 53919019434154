<header>
  <ul>
    <li routerLink="/">
      <span class="icon-ivestor_Logo_beta_01"><span class="path1"></span><span class="path2"></span><span
          class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span
          class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span
          class="path11"></span><span class="path12"></span><span class="path13"></span><span
          class="path14"></span><span class="path15"></span><span class="path16"></span></span>
    </li>
    <li *ngIf="naviType === 'regular'" class="main-navigation">
      <app-main-navigation></app-main-navigation>
    </li>
    <li routerLink="/favorites" [attr.title]="'button.watchlist.title'|translate">
      <span [ngClass]="{ 'icon-favorits-active': favoriteActive, 'icon-favorits': !favoriteActive }"><span
          class="path1"></span><span class="path2"></span></span>
    </li>
    <li *ngIf="naviType === 'mobile'">
      <button class="toggle-mobile icon-burger" (click)="toggleMobileNavigation()"
        [ngClass]="{'icon-close': showMobileNavi }" aria-label="mobile menu"></button>
      <app-mobile-navigation *ngIf="showMobileNavi" (trigger)="toggleMobileNavigation()"></app-mobile-navigation>
    </li>
  </ul>
</header>
