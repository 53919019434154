import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { SortTableData } from 'src/app/helper/helper';
import { DataService } from 'src/app/services/data-service/data.service';
import { Instrument } from 'src/app/services/instrument-service/instrument.config';
import { NotifierService } from 'src/app/services/notifier-service/notifier.service';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {
  private store: BehaviorSubject<string[]> = new BehaviorSubject([]);
  readonly DATA: BehaviorSubject<Instrument[]> = new BehaviorSubject([]);

  constructor(
    private dataService: DataService,
    private notifierService: NotifierService
  ) { 
    this.store.subscribe((data: string[]) => this.getInstruments(data));
    this.store.next(this.getStore());
  }

  deleteInstrument(isin: string):void {
    const store = this.store.getValue();
    store.splice(store.indexOf(isin), 1);
    this.store.next(store);
    this.updateStore(store);
  }

  addInstrument(data: Instrument):void {
    const store = this.store.getValue();
    if (store.indexOf(data.isin.value) === -1) {
      store.push(data.isin.value);
      this.store.next(store);
      this.updateStore(store);
      this.notifierService.add(`${data.name.value} (${data.isin.value}) zu Favoriten hinzugefügt`);
    } else {
      this.notifierService.add(`${data.name.value} (${data.isin.value}) ist bereits in ihren Favoriten vorhanden`);
    }
  }

  private updateStore(data: string[]): void {
    localStorage.setItem('ivestorFavorites', JSON.stringify(data));
  }

  private getStore(): string[] {
    const storage = localStorage.getItem('ivestorFavorites');
    if (storage) {
      return JSON.parse(storage);
    } else {
      return [];
    }
  }

  private getInstruments(data: any[]): void {
    const instruments: Instrument[] = [];
    const ready: boolean[] = [];
    const loader: BehaviorSubject<number> = new BehaviorSubject(0);
    data.forEach((i: string, int: number) => {
      this.dataService.getData('isin', i, 'instrument')
        .pipe(take(1))
        .subscribe({
          next: (instrument: Instrument) => {
            instruments.push(instrument);
            ready.push(true);
            loader.next(int);
          },
          error: () => {
            instruments.push({
              isin: {
                value: i
              },
              name: {
                value: `${i} (Aktuell keine Daten vorhanden)`
              }
            });
            ready.push(true);
            loader.next(int);
          }
        });
    });
    loader.subscribe(() => {
      if (ready.length === data.length) {
        this.DATA.next(SortTableData(instruments, 'name', 'asc'));
      }
    })
  }

}
