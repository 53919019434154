import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IsWeekend } from '../date-picker/date-picker.helper';
import { FiltersDateConfig } from './filter-date.config';

@Component({
  selector: 'app-filters-date',
  templateUrl: './filters-date.component.html'
})

export class FiltersDateComponent implements OnInit {
  @Input() data: any;
  @Output() submitForm: EventEmitter <any> = new EventEmitter();
  datePickerConfig: FiltersDateConfig;
  date: Date;

  ngOnInit(): void {
    this.datePickerConfig = {
      minDate: this.data.minDate,
      maxDate: this.data.maxDate,
      disableWeekend: !this.data.weekend,
      disableDates: this.data.holidays
    };
  }

  onSelectMonthHandler(data: any): void {
    this.date = data;
    this.prepareFormDataMonth();
    this.submit();
  }

  checkWeekend(data: Date, minus?: boolean): Date {
    let day: number;
    let tmpDate: Date;
    if (!IsWeekend(data) || !this.datePickerConfig.disableWeekend) { return data; }
    if (minus) {
      day = data.getDate() - 1;
      tmpDate = new Date(data.getFullYear(), data.getMonth(), day, 23, 59, 59);
      return !IsWeekend(tmpDate) ? tmpDate : new Date(data.getFullYear(), data.getMonth(), day - 1, 23, 59, 59);
    } else {
      day = data.getDate() + 1;
      tmpDate = new Date(data.getFullYear(), data.getMonth(), day, 0, 0, 0);
      return !IsWeekend(tmpDate) ? tmpDate : new Date(data.getFullYear(), data.getMonth(), day + 1, 0, 0, 0);
    }
  }

  prepareFormDataMonth(): void {
    const currentYear = this.date.getFullYear();
    const currentMonth = this.date.getMonth();
    this.data.from = new Date(currentYear, currentMonth, 1, 0, 0, 0);
    this.data.to = new Date(currentYear, currentMonth + 1, 0, 23, 59, 59);
    this.data.rangeFrom.setValue(this.checkWeekend(this.data.from));
    this.data.rangeTo.setValue(this.checkWeekend(this.data.to, true));
  }

  rangeHandler(): void {
    if (this.data.rangeFrom.invalid || this.data.rangeTo.invalid) { return; }
    if (!this.data.rangeFrom.value || !this.data.rangeTo.value) { return; }
    const toEndOfDay = this.data.rangeTo.value;
    this.data.from = this.data.rangeFrom.value;
    this.data.to = new Date(toEndOfDay.getFullYear(), toEndOfDay.getMonth(), toEndOfDay.getDate(), 23, 59, 59);
    this.submit();
  }

  submit(): void {
    this.submitForm.emit();
  }
}
