import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EventEmitterData, Underlying } from 'src/app/app.config';
import { DataService } from 'src/app/services/data-service/data.service';
import { UnderlyingSelect, UnderlyingSelectConfig, UnderlyingSelectItem } from './underlying-select.config';

@Component({
  selector: 'app-underlying-select',
  templateUrl: './underlying-select.component.html',
  styleUrls: ['./underlying-select.component.scss']
})
export class UnderlyingSelectComponent implements OnInit, OnChanges {
  @Input() data: string;
  @Input() updateBenchmarks: Underlying[];
  @Output() changed: EventEmitter<EventEmitterData> = new EventEmitter();
  @Output() closed: EventEmitter<boolean> = new EventEmitter();
  config: UnderlyingSelect = UnderlyingSelectConfig;
  sorting: string;
  error: string;
  underlyings: UnderlyingSelectItem[];
  filteredUnderlyings: UnderlyingSelectItem[];
  locked: boolean;

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.dataService.getData(
      '',
      '',
      'instrument',
      '',
      'lists/benchmark',
    ).subscribe({
      next: (data: any) => {
        this.underlyings = this.mapUnderlyingData(data.instruments);
        this.filteredUnderlyings = [ ...this.config.default, ...this.underlyings];
      },
      error: (error: string) => this.showError(error)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.updateBenchmarks && !changes.updateBenchmarks.firstChange) {
      this.updateSelected([...this.config.default, ...this.filteredUnderlyings], changes.updateBenchmarks.currentValue);
      this.checkLocked();
    } else if (changes.data && !changes.data.firstChange) {
      this.filteredUnderlyings = this.filterBySearchString([ ...this.config.default, ...this.underlyings], this.data);
    }
  }

  setSorting(data?: string): void {
    this.changed.emit({
      type: 'searchUpdate',
      value: ''
    });
    this.sorting = data;
    
    if (!data) {
      this.filteredUnderlyings = [...this.config.default, ...this.underlyings];
    } else {
      this.filteredUnderlyings = this.filterUnderlyings(this.underlyings, this.sorting);
    }
  }

  filterUnderlyings(data: UnderlyingSelectItem[], sorting: string): UnderlyingSelectItem[] {
    return data.filter((underlying: UnderlyingSelectItem) => underlying.label === sorting);
  }

  selectHandler(data: Underlying): void {
    if (!data.selected && this.checkLocked()) {
      return;
    } else {
      data.selected = !data.selected;
    }
    this.checkLocked();
  }

  checkLocked(): boolean {
    this.locked = this.getSelectedUnderlyings([...this.config.default, ...this.underlyings]).length === this.config.selectionMax || false;
    return this.locked;
  }

  mapUnderlyingData(data: Underlying[]): UnderlyingSelectItem[] {
    const underlyings: UnderlyingSelectItem[] = [];
    this.config.alphabet.forEach((l: string) => {
      underlyings.push({
        label: l,
        values: data.filter((d: Underlying) => d.name.value[0] === l).sort((a: Underlying, b: Underlying) => {
          return a.name.value.localeCompare(b.name.value);
        })
      });
    });
    underlyings.push({
      label: '0-9',
      values: data.filter((d: Underlying) => d.name.value[0].match(/^\d*$/g))
    });
    return underlyings;
  }

  getSelectedUnderlyings(data: UnderlyingSelectItem[]): Underlying[] {
    let selectedUnderlyings: Underlying[] = [];
    data.forEach((u: UnderlyingSelectItem) => {
      const selected = u.values.filter((v: Underlying) => v.selected);
      if (selected.length) {
        selectedUnderlyings = [ ...selectedUnderlyings, ...selected];
      }
    });
    return selectedUnderlyings;
  }

  filterBySearchString(data: UnderlyingSelectItem[], search: string): UnderlyingSelectItem[] {
    let filteredData: UnderlyingSelectItem[] = [];
    data.forEach((u: UnderlyingSelectItem) => {
      const selected = u.values.filter((v: Underlying) => v.name.value.toLowerCase().match(search.toLowerCase()));
      if (selected.length) {
        filteredData = [ ...filteredData, {
          ...u,
          values: selected
        }];
      }
    });
    return filteredData;
  }

  updateSelected(data: UnderlyingSelectItem[], benchmarks: Underlying[]): UnderlyingSelectItem[] {
    data.forEach((u: UnderlyingSelectItem) => {
      u.values.forEach((v: Underlying) => {
        v.selected = benchmarks.filter((b: Underlying) => b.isin.value === v.isin.value).length ? true : false;
      });
    });
    return data;
  }

  showError(message: string): void {
    this.error = message;
  }

  submitHandler(): void {
    this.changed.emit({
      type: 'selectionUpdate',
      value: this.getSelectedUnderlyings([...this.config.default, ...this.underlyings])
    });
  }

  closeHandler(): void {
    this.closed.emit();
  }

}
