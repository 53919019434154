import { Injectable } from '@angular/core';
import { getPriceDecimals } from '../../helper/helper';
import { MinMaxPipe } from '../../pipes/minMax/min-max.pipe';

@Injectable({
  providedIn: 'root'
})
export class InstrumentService {

  constructor(
    private minMaxPipe: MinMaxPipe
  ) {}

  getConfigQuotes(data: any) {
    const maxPriceDecimals: number = getPriceDecimals(data);
    const market = this.getMarketData(data.market, data.marketListings);
    const decimalConfig: string = this.minMaxPipe.transform(
      data.price.value || null,
      data.instrumentGroup.value,
      maxPriceDecimals
    );
    const identifier = {
      type: 'sin',
      value: data.sin.value
    };

    return {
      identifier,
      decimalConfig,
      type: 'price',
      suffix: ' ' + data?.market?.currency,
      instrumentGroup: data.instrumentGroup.value,
      currency: data?.market?.currency,
      sizesConfig: {
        type: 'volume',
        suffix: ' Stk.'
      },
      push: true,
      market
    };
  }

  getMarketData(market: any, listings: any[]): any {
    const currentMarket = listings?.filter((l) => l.id === market.id);
    if (!currentMarket || !currentMarket.length) { return market; }
    return {
      id: currentMarket[0].id,
      weekend: currentMarket[0].weekend ? currentMarket[0].weekend : false,
      holidays: currentMarket[0].holidays ? currentMarket[0].holidays.map((h: Date) => new Date(h)) : [],
      currency: currentMarket[0].currency
    };
  }
}
