export interface DonutChartConfig {
  donut: boolean;
  donutWidth: number;
  startAngle: number;
  showLabel: boolean;
  plugins: any[];
  suffix: string;
  total?: number;
  targetUrl?: string;
  targetValue?: string;
}

export const DonutChartConfigDefault = {
  donut: true,
  donutWidth: 20,
  startAngle: 0,
  showLabel: false,
  suffix: ' Produkte'
}
