import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ClickedConfig } from 'src/app/app.config';
import { MainSearchData } from '../search.config';
import { SearchService } from '../search.service';
import { MainSearchCategory, MainSearchCategoryValue, MainSearchInput } from './main-search-input.config';
import { SmartSearchService } from '@solvians-frontend-ivestor/smart-search';

@Component({
  selector: 'app-main-search-input',
  templateUrl: './main-search-input.component.html',
  styleUrls: ['./main-search-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainSearchInputComponent implements OnInit {
  @Input() config: MainSearchInput;
  @Output() clicked: EventEmitter<ClickedConfig> = new EventEmitter();
  @Output() changed: EventEmitter<ClickedConfig> = new EventEmitter();
  currentSelection: MainSearchCategory;
  active:boolean;
  searchTerm: string;
  timeout: any;
  preloader: boolean;

  constructor(
    private eRef: ElementRef,
    private searchService: SearchService,
    private smartSearchService: SmartSearchService,
    private router: Router
  ) {}
  @HostListener('document:click', ['$event'])
  clickout(event:any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.setActiveStatus(false);
    }
  }

  ngOnInit():void {
    this.currentSelection = this.getCurrentSelection(this.config.categories);
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.resetAll(this.config.categories);
        if (this.router.url.indexOf('/markets/search') !== -1) {
          this.currentSelection = this.getCurrentSelection(this.config.categories, 'derivatives');
          this.searchService.setCurrentSearch('derivatives');
        } else {
          this.currentSelection = this.getCurrentSelection(this.config.categories, '');
          this.searchService.setCurrentSearch('');
          this.searchService.clearSearch();
        }
      }
    });
    this.searchService.store.subscribe((data: MainSearchData) => {
      if (!data) { this.searchTerm = ''; }
    });
    this.searchService.loading.subscribe((data: boolean) => {
      this.preloader = data;
    });
  }

  onClickHandler(item?: MainSearchCategoryValue): void {
    this.resetAll(this.config.categories);
    if (item) { 
      item.selected = true; 
      this.currentSelection = this.getCurrentSelection(this.config.categories);
    } else {
      this.currentSelection = null;
    }
    this.searchService.setCurrentSearch(item?.name);
    this.searchService.clearSearch();
    this.smartSearchService.setSearchTerm('');
    this.setActiveStatus(false);
  }

  onClickHandlerButton(): void {
    this.searchService.clearSearch();
    this.searchTerm = '';
  }

  onClickOpenHandler():void {
    this.setActiveStatus(!this.active);
  }

  onChangeHandlerMainSearch(data: string): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.searchService.setSearchTerm(data);
    }, 150);
  }

  onFocusHandler(data: boolean): void {
    this.timeout = setTimeout(() => {
      this.searchService.setInputFocus(data);
    }, 150); 
  }

  setActiveStatus(data: boolean): void {
    this.active = data;
    this.changed.emit({ type: 'MainSearchInputState', value: data });
  }

  resetAll(data: MainSearchCategory[]): void {
    data.forEach((category: MainSearchCategory) => {
      category.values.forEach((item: MainSearchCategoryValue) => item.selected = false);
    });
  }

  getCurrentSelection(data: MainSearchCategory[], name?: string): MainSearchCategory {
    let selection: any;
    data.forEach((category: MainSearchCategory) => {
      let selected = name ? 
        category.values.filter((item: MainSearchCategoryValue) => item.name = name)[0] : 
        category.values.filter((item: MainSearchCategoryValue) => item.selected)[0];
      if (selected) {
        selection = {
          ...selected,
          category: category.value
        };
      }
    })
    return selection;
  }

}
