import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatePickerComponentConfig, DatePickerComponentData } from '../date-picker/date-picker.config';

@Component({
  selector: 'app-custom-date-select',
  templateUrl: './custom-date-select.component.html'
})
export class CustomDateSelectComponent {
  @Input() data!: DatePickerComponentData;
  @Input() config!: DatePickerComponentConfig;
  @Output() changed: EventEmitter<any> = new EventEmitter();

  rangeHandler(): void {
    if (this.data.rangeFrom.invalid || this.data.rangeTo.invalid) { return; }
    if (!this.data.rangeFrom.value || !this.data.rangeTo.value) { return; }
    this.changed.emit(this.data);
  }

}
