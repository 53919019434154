import { DecimalPipe } from '@angular/common';
import { ChartHelper } from '@solvians-frontend-ninjas/core-charts';

export class CustomChartHelper extends ChartHelper {
    private decimalPipe = new DecimalPipe('de');

    constructor() {
      super();
     }

    FormatShortcuts(data: number, decimalConfig?: string): string {
      let suffix = '';
      let value: number;
      if (data >= 1000000) {
        suffix = 'M';
        value = data / 1000000;
      } else if (data >= 1000) {
        suffix = 'k';
        value = data / 1000;
      } else {
        value = data;
      }
      return `${this.decimalPipe.transform(value, decimalConfig)}${suffix}`;
    }

    FormatValue(data: string, options?: any): any {
      const value = Number(data);
      let valueStr: string;
      if (options?.shortcuts) {
        valueStr = this.FormatShortcuts(value, options?.decimalConfig);
      } else if (options?.percents) {
        valueStr = this.FormatPercents(value);
      } else {
        valueStr = `${this.decimalPipe.transform(value, options?.decimalConfig)}`;
      }
      return valueStr;
    }

    override getHours(data: number, showSeconds?: boolean): string {
      const date = new Date(data)
      const rawHours = date.getHours()
      const rawMinutes = date.getMinutes()
      const rawSeconds = date.getSeconds()
      const hours = rawHours < 10 ? `0${rawHours}` : rawHours
      const minutes = rawMinutes < 10 ? `0${rawMinutes}` : rawMinutes
      const seconds = rawSeconds < 10 ? `0${rawSeconds}` : rawSeconds
      return showSeconds ? `${hours}:${minutes}:${seconds}` : `${hours}:${minutes}`
    }

  }
