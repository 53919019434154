<ul>
  <li *ngFor="let main of categories" (mouseenter)="main.open = true" (mouseleave)="main.open = false; toggleAll()">
    <a class="mainnavi {{main.id}}" [ngClass]="{'active': mainnaviActive === main.id}">
      {{main.name}}
    </a>
    <ul class="subnavi" *ngIf="main.entries && main.open">
      <li *ngFor="let sub of main.entries">
        <span class="label-menu" [ngClass]="{'active': sub.active}" *ngIf="sub.list" (click)="toggleSubNavi(sub)">
          {{sub.name}}
        </span>
        <a class="label" [routerLink]="[main.id, sub.id]" routerLinkActive="active" *ngIf="!sub.list"
          (click)="main.open = false">
          {{sub.name}}
        </a>
        <ul class="thirdnavi" *ngIf="sub.active">
          <li *ngFor="let third of sub.list">
            <a [routerLink]="[main.id, third.id]" routerLinkActive="active" (click)="main.open = false">
              {{third.name}}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </li>
</ul>
