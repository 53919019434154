import { SimpleTable } from "src/app/sharedComponents/simple-table/simple-table.config";

export interface Favorites {
  table: SimpleTable;
  tiles: any;
}

export const breadcrumbConfig = [];
export const FavoritesConfig: Favorites = {
  table: {
    menu: ['favorites'],
    tableSorting: {
      id: 'name',
      sorting: 'asc'
    },
    properties: [{
      label: 'name',
      id: 'name',
      type: 'text',
      instrumentLink: true
    }, {
      label: '',
      id: 'chart',
      type: 'chart',
      url: '/chart/sin'
    }, {
      label: 'quote',
      id: 'price',
      type: 'price',
      currency: true
    }, {
      label: 'performance',
      id: 'performance',
      type: 'performance',
      src: 'value',
      calcPerformanceClass: true,
      currency: true,
      decimalConfig: '1.2-2',
      colspan: 2
    }, {
      label: 'performancePercent',
      id: 'performance',
      type: 'performance',
      src: 'percent',
      calcPushClass: true
    }, {
      label: 'time',
      id: 'price',
      type: 'dateOrTime',
      src: 'time',
      format: 'mediumTime'
    }]
  },
  tiles: {
    menu: ['favorites'],
    tableSorting: {
      id: 'name',
      sorting: 'asc'
    },
    properties: [{
      childs: [{
        label: 'name',
        id: 'name',
        type: 'text',
        instrumentLink: true
      }]
    }, {
      childs: [{
        label: 'chart',
        id: 'chart',
        type: 'chart',
        url: '/chart/sin'
      }]
    }, {
      childs: [{
          label: 'quote',
          id: 'price',
          type: 'price',
          currency: true
        },
        {
          label: 'performance',
          id: 'performance',
          type: 'performance',
          src: 'value',
          currency: true,
          calcPerformanceClass: true
        }
      ]
    }, {
      childs: [{
        label: 'time',
        id: 'price',
        type: 'dateOrTime',
        src: 'time',
        format: 'mediumTime'
      }, {
        label: 'performancePercent',
        id: 'performance',
        type: 'performance',
        src: 'percent',
        calcPushClass: true
      }]
    }]
  }
}
