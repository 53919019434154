import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AssociatedDerivatives } from './associated-derivatives.config';

@Component({
  selector: 'app-associated-derivatives',
  templateUrl: './associated-derivatives.component.html',
  styleUrls: ['./associated-derivatives.component.scss']
})
export class AssociatedDerivativesComponent implements OnInit {
  @Input() data: any;
  @Input() config: AssociatedDerivatives;
  limit: number[];
  showMoreButton: boolean[];
  showPercents: boolean;
  showChart: boolean;

  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {
    this.data = this.sortInstrumentGroups(this.data);
    this.mapLimits(this.config);
    this.setMoreLink(this.data);
    if (this.config.calcPercent) {
      setTimeout(() => this.setPercentValues(),10);
    }
  }

  sortInstrumentGroups(data: any): any {
    const newOrder = [];
    this.config.orderedGroups.forEach((o: string) => {
      newOrder.push(data.filter((d: any) => d.label === o)[0]);
    });
    return newOrder.filter((d: any) => d !== undefined);
  }

  setMoreLink(data: any): void {
    for (let i = 0; i < data.length; i++) {
      if (data[i].values.length > this.config.limitMin) {
        this.showMoreButton[i] = true;
      }
    }
  }

  setPercentValues(): void {
    this.data.forEach((o: any) => o.values.forEach((v:any) => {
      v.percent = (v.count * 100) / o.count
    }));
    this.showPercents = true;
  }

  mapLimits(config: AssociatedDerivatives): void {
    this.limit = this.config.orderedGroups.map(() => config.limitMin);
    this.showMoreButton = this.config.orderedGroups.map(() => false);
  }

  setLimit(index: number) {
    this.limit[index] = this.limit[index] < this.config.limitMax ? this.config.limitMax : this.config.limitMin;
  }

  triggerSearch(data: any): void {
    if (!this.config.targetUrl) { return }
    this.router.navigate([`${this.config.targetUrl}/${data[this.config.targetValue]}`]);
  }
}
