import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FiltersConfigData } from './filters.config';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})

export class FiltersComponent  {
  @Input() data: any;
  @Output() submitted: EventEmitter <any> = new EventEmitter();
  @Output() changed: EventEmitter <any> = new EventEmitter();
  @Output() focussed: EventEmitter <any> = new EventEmitter();

  getFilterParams(data: Array<FiltersConfigData>): any {
    const filterData = {};
    const selectBoxes = data.filter((t: any) => t.type === 'select');
    selectBoxes.forEach((f: FiltersConfigData) => {
      const value = this.getFilterValue(f);
      if (value) { filterData[f.name] = this.getFilterValue(f); }
    });
    return filterData;
  }

  getValueFromListEntries(data: any): any {
    const selected = data.filter((e: any) => e.selected);
    return selected[0];
  }

  getFilterValue(data: FiltersConfigData): any {
    const value = this.getValueFromListEntries(data.values);
    return value ? value.value : '';
  }

  selectionChanged(data: any): void {
    this.changed.emit(data);
    this.submitted.emit(
      this.getFilterParams(this.data)
    );
  }

  inputKeyUp(data: any): void {
    this.changed.emit(data);
  }

  inputFocus(data: any): void {
    this.focussed.emit(data);
  }
}
