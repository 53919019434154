<div class="content" *ngIf="show">
  <div class="content-wrapper">
    <div class="component">
      <h1>Buttons</h1>
      <p>
        <span style="display:inline-block; width:200px;">Primary Green</span>
        <button class="primary small green">Small</button>
        <button class="primary medium green">Medium</button>
        <button class="primary green">Standard</button>
        <button class="primary green icon">
          <span class="icon-micro"></span>
          <span class="text">Standard with icon</span>
        </button>
      </p>
      <p>
        <span style="display:inline-block; width:200px;">Primary Cold-Black</span>
        <button class="primary small cold-black">Small</button>
        <button class="primary medium cold-black">Medium</button>
        <button class="primary cold-black">Standard</button>
        <button class="primary cold-black icon">
          <span class="icon-micro"></span>
          <span class="text">Standard with icon</span>
        </button>
      </p>
      <p>
        <span style="display:inline-block; width:200px;">Secondary Cold-Black</span>
        <button class="secondary small cold-black">Small</button>
        <button class="secondary medium cold-black">Medium</button>
        <button class="secondary cold-black">Standard</button>
        <button class="secondary cold-black icon">
          <span class="icon-micro"></span>
          <span class="text">Standard with icon</span>
        </button>
      </p>
      <p>
        <span style="display:inline-block; width:200px;">Ghost</span>
        <button class="secondary small ghost">Small</button>
        <button class="secondary medium ghost">Medium</button>
        <button class="secondary ghost">Standard</button>
        <button class="secondary ghost icon">
          <span class="icon-micro"></span>
          <span class="text">Standard with icon</span>
        </button>
      </p>
      <p>
        <span style="display:inline-block; width:200px;">Round Green</span>
        <button class="round medium green">Go</button>
        <button class="round green">Go</button>
        <button class="round green icon">
          <span class="icon-micro"></span>
        </button>
      </p>
      <p>
        <span style="display:inline-block; width:200px;">Round Ghost</span>
        <button class="round medium ghost">Go</button>
        <button class="round ghost">Go</button>
        <button class="round ghost icon">
          <span class="icon-micro"></span>
        </button>
      </p>
      <p>
        <span style="display:inline-block; width:200px;">Reset</span>
        <button class="round medium reset default">
          <span class="icon-reset"></span>
        </button>
        <button class="round reset default">
          <span class="icon-reset"></span>
        </button>
      </p>
      <p>
        <span style="display:inline-block; width:200px;">Icons</span>
        <button class="medium ghost-icon default">
          <span class="icon-burger"></span>
        </button>
      </p>
      <p>
        <span style="display:inline-block; width:200px;">Menu Icons</span>
        <button class="round medium menu">
          <span class="icon-share"></span>
        </button>
        <button class="round medium snow-man">
          <span class="icon-snowman"></span>
        </button>
      </p>
    </div>
    <div class="component">
      <h1>Headline 1</h1>
      <h2>Headline 2</h2>
      <h3>Headline 3</h3>
      <h4>Headline 4 (.header-4)</h4>
      <h5>Headline 5 (.header-5)</h5>
    </div>
  </div>
</div>
