import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { GetTableCSSClass } from 'src/app/helper/helper';
import { ListTilesChartConfig } from './list.tiles.config';
import { ChartConfig, ChartThemeConfig } from '@solvians-frontend-ninjas/core-charts';

@Component({
  selector: 'app-list-tiles',
  templateUrl: './list-tiles.component.html',
  styleUrls: ['./list-tiles.component.scss', './list-tiles.group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListTilesComponent implements OnInit {
  @Input() data: any;
  @Input() config: any;
  @Input() group: string;
  getCSSClass = GetTableCSSClass;
  chartConfig: ChartConfig;

  ngOnInit(): void {
    ChartThemeConfig.listTiles = ListTilesChartConfig;
    this.chartConfig = {
      theme: 'listTiles'
    };
  }
}
