import {
  Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
  transform(items: any[], fieldName: string, reverse: boolean = false): any[] {
    if (!items) {
      return [];
    }
    const sortedArray = [...items].sort((a: any, b: any) => {
      return a[fieldName].localeCompare(b[fieldName]);
    });
    return reverse ? sortedArray.reverse() : sortedArray;
  }
}
