<ul class="main-search-selection" [ngClass]="{'active':active, 'full': !currentSelection}">
  <li class="default" *ngIf="!currentSelection" (click)="onClickOpenHandler()">
    <span class="icon-ive"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span
        class="path4"></span><span class="path5"></span></span>
    <span class="label">{{'all'|translate}}</span>
  </li>
  <li [ngClass]="currentSelection.category" *ngIf="currentSelection" (click)="onClickOpenHandler()">
    <span class="label">{{currentSelection.name|translate}}</span>
  </li>
  <li class="main-search-input" *ngIf="!currentSelection">
    <input type="text" name="searchTerm" [(ngModel)]="searchTerm" (ngModelChange)="onChangeHandlerMainSearch($event)"
      (focus)="onFocusHandler(true)" (blur)="onFocusHandler(false)" data-shortcut="1" autocomplete="off"
      autocorrect="off" autocapitalize="off" spellcheck="false" maxlength="300" aria-label="Search" />
    <button type="submit" (click)="onClickHandlerButton()" [ngClass]="{ 'delete': searchTerm?.length > 0, 'loading': preloader }" aria-label="submit"></button>
  </li>
</ul>
<ul class="main-search-list" *ngIf="active">
  <li class="all" *ngIf="currentSelection" (click)="onClickHandler()">
    <span class="icon-ive"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span
        class="path4"></span><span class="path5"></span></span>
    <span class="label">{{'all'|translate}}</span>
  </li>
  <li *ngFor="let category of config.categories" [ngClass]="category.value">
    <span class="label">{{category.name|translate}}</span>
    <ul>
      <li *ngFor="let item of category.values" [ngClass]="{'selected':item.selected}" (click)="onClickHandler(item)">
        {{item.name|translate}}</li>
    </ul>
  </li>
</ul>
