export const BasedataConfig = (config: any) => {
  return {
    static: [{
      label: 'category',
      config: {
        type: 'text'
      }
    }, {
      label: 'subCategory',
      config: {
        type: 'text'
      }
    }, {
      label: 'strategy',
      config: {
        type: 'text'
      }
    }, {
      label: 'issuer',
      config: {
        type: 'text'
      }
    }, {
      label: 'wkn',
      config: {
        type: 'text'
      }
    }, {
      label: 'isin',
      config: {
        type: 'text'
      }
    }, {
      label: 'symbol',
      config: {
        type: 'text'
      }
    }, {
      label: 'realPriceCurrency',
      config: {
        type: 'text'
      }
    }, {
      label: 'branch',
      config: {
        type: 'text'
      }
    }, {
      label: 'country',
      config: {
        type: 'text'
      }
    }, {
      label: 'sector',
      config: {
        type: 'text'
      }
    }, {
      label: 'numberOfShares',
      config: {
        type: 'volume',
        suffix: ' Stk.'
      }
    }, {
      label: 'launchDate',
      config: {
        type: 'date',
        format: 'mediumDate'
      }
    }, {
      label: 'strike',
      config: {
        type: 'productLevel'
      }
    }, {
      label: 'barrier',
      config: {
        type: 'productLevel'
      }
    }, {
      label: 'koBarrier',
      config: {
        type: 'productLevel'
      }
    }, {
      label: 'bonus',
      config: {
        type: 'productLevel'
      }
    }, {
      label: 'security',
      config: {
        type: 'productLevel'
      }
    }, {
      label: 'cap',
      config: {
        type: 'productLevel'
      }
    }, {
      label: 'coupon',
      config: {
        type: 'number',
        decimalConfig: '1.2-2'
      }
    }, {
      label: 'factor',
      config: {
        type: 'number',
        decimalConfig: '1.2-2'
      }
    }, {
      label: 'maturity',
      config: {
        type: 'maturity',
        format: 'mediumDate'
      }
    }],
    keyfacts: [{
      label: 'turnover',
      config: {
        type: 'volume',
        suffix: config.suffix
      }
    }, {
      label: 'tradedVolumeDay',
      config: {
        type: 'volume',
        suffix: ' Stk.'
      }
    }, {
      label: 'openPrice',
      config: {
        type: 'price',
        suffix: config.suffix,
        decimalConfig: config.decimalConfig
      }
    }, {
      label: 'previousClose',
      config: {
        type: 'price',
        suffix: config.suffix,
        decimalConfig: config.decimalConfig
      }
    }, {
      label: 'highDay',
      config: {
        type: 'price',
        suffix: config.suffix,
        decimalConfig: config.decimalConfig
      }
    }, {
      label: 'lowDay',
      config: {
        type: 'price',
        suffix: config.suffix,
        decimalConfig: config.decimalConfig
      }
    }, {
      label: 'high52Wk',
      config: {
        type: 'price',
        suffix: config.suffix,
        decimalConfig: config.decimalConfig
      }
    }, {
      label: 'low52Wk',
      config: {
        type: 'price',
        suffix: config.suffix,
        decimalConfig: config.decimalConfig
      }
    }],
    marketData: [{
      label: 'marketCapitalization',
      config: {
        type: 'volume',
        suffix: config.suffix
      }
    }, {
      label: 'numberOfShares',
      config: {
        type: 'number',
        suffix: config.suffix
      }
    }, {
      label: 'freefloat',
      config: {
        type: 'percents',
        src: 'percent'
      }
    }],

  };
};
