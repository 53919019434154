import { Component, Input, OnInit } from '@angular/core';
import { Instrument } from 'src/app/services/instrument-service/instrument.config';
import { MediaQueryService } from 'src/app/services/mediaquery-service/mediaquery-service.service';
import { FavoritesConfig } from './favorites.component.config';
import { SimpleTable } from 'src/app/sharedComponents/simple-table/simple-table.config';
import { FavoritesService } from './favorites.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
  @Input() config: any;
  data: Instrument[] = [];
  breadcrumbConfig: any[] = [];
  error: string;
  showPreloader: boolean;
  media: string;
  tilesView = false;
  configTable: SimpleTable = FavoritesConfig.table;
  configTiles = FavoritesConfig.tiles;

  constructor(
    private mediaQueryService: MediaQueryService,
    private favoritesService: FavoritesService
  ) { }

  ngOnInit(): void {
    this.showPreloader = true;
    this.favoritesService.DATA.subscribe((data: Instrument[]) => {
      this.data = data;
      setTimeout(() => {
        this.error = data.length ? '' : 'favorites.error';
        this.showPreloader = false;
      }, 100);
    });
    this.mediaQueryService.MEDIA.subscribe((media: string) => {
      this.media = media;
    });
  }
}
