<button [matMenuTriggerFor]="matMenu" class="round medium snow-man">
  <span class="icon-snowman"></span>
</button>
<mat-menu #matMenu="matMenu" xPosition="before" class="flyout functions-list">
  <ul>
    <li>
      <button class="delete" (click)="onClickHandler()">
        <span class="icon-trash"></span> {{'delete'|translate}}
      </button>
    </li>
  </ul>
</mat-menu>
