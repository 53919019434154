import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FiltersConfigData, FiltersConfigSelectData } from '../filters/filters.config';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
})
export class FilterSelectComponent implements OnInit {
  @Input() data: FiltersConfigData;
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter();
  selected: string;

  ngOnInit(): void {
    const selected = this.data.values.filter((e: FiltersConfigSelectData) => e.selected);
    this.selected = selected.length ? selected[0].value : '';
  }

  selectionChange(data: any): void {
    const selectedValues = this.data.values.filter((e: FiltersConfigSelectData) => e.selected);
    const currentSelection = this.data.values.filter((e: FiltersConfigSelectData) => e.value === data.value);
    if (selectedValues.length) { selectedValues[0].selected = false; }
    if (currentSelection.length) { currentSelection[0].selected = true; }
    this.selectionChanged.emit(data);
  }
}
