import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CompanyProfile } from './company-profile.config';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CompanyProfileComponent {
  @Input() data: any;
  @Input() config: CompanyProfile;
}
