import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { DatePickerDateAdapter, DATE_PICKER_DATE_FORMAT } from './date-picker.component.format';
import { DateMatches } from '../../helper/helper';
import { DatePickerComponentConfig, DatePickerComponentData } from './date-picker.config';
import { IsWeekend } from './date-picker.helper';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  providers: [
    { provide: DateAdapter, useClass: DatePickerDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: DATE_PICKER_DATE_FORMAT }
  ]
})
export class DatePickerComponent implements OnInit {
  @Input() data: DatePickerComponentData;
  @Input() label: string;
  @Input() config: DatePickerComponentConfig;
  @Output() changeDate: EventEmitter<any> = new EventEmitter();
  disableHandler: () => Date;

  ngOnInit(): void {
    setTimeout(() => {
      this.disableHandler = this.disableDatesHandler.bind(this);
    });
  }

  submit(): void {
    this.changeDate.emit();
  }

  disableDatesHandler(date: Date): Date {
    if (!date) { return; }
    let matches = 0;
    if (this.config.disableDates && DateMatches(this.config.disableDates, date)) {
      matches++;
    }
    if (this.config.disableWeekend && IsWeekend(date)) {
      matches++;
    }
    return matches ? null : date;
  }
}
