<div class="wrapper" (click)="navigateTo(data.id)">
  <div class="image">
    <img [src]="data?.img || '/assets/img/pixel.png'" alt="" />
  </div>
  <div class="content">
    <div class="category">{{data.category|translate}}</div>
    <div class="source">{{data.firstCreated|date:'dd.MM.yyy / HH:mm:ss'}} ({{data.source}})</div>
    <div class="title">{{data.title}}</div>
  </div>
</div>
