import {
  ChartConfig,
  DefaultChartConfig
} from '@solvians-frontend-ninjas/core-charts';

export const ListTilesChartConfig: ChartConfig = {
  ...DefaultChartConfig,
  highLow: true,
  targetLines: true,
  threshold: true,
  responsiveOptions: [
    'screen and (min-width: 600px)',
    {
      height: '23px',
      chartPadding: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      }
    },
  ],
};
