import { Component, Input, OnInit } from '@angular/core';
import { MediaQueryService } from 'src/app/services/mediaquery-service/mediaquery-service.service';
import { TabsConfig, TabsItemConfig } from './tabs.config';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @Input() data: Array<TabsItemConfig>;
  @Input() config: TabsConfig;
  styles: any;
  media: string;

  constructor(
    private mediaQueryService: MediaQueryService
  ) {}

  ngOnInit() {
    this.styles = this.setCSSClasses();
    this.mediaQueryService.MEDIA.subscribe((media: string) => {
      this.media = media;
    });
  }

  setCSSClasses(): string {
    return `${this.config?.subnavi ? 'subnavi' : ''} ${this.config?.style ? this.config.style : ''}`;
  }

  setScrollPosition(flag: boolean): void {
    if (!flag) {
      return;
    }
    window.scroll(0, 0);
  }
}
