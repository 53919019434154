import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { BasedataConfig } from './basedata.config';

@Component({
  selector: 'app-basedata',
  templateUrl: './basedata.component.html',
  styleUrls: ['./basedata.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BasedataComponent implements OnInit {
  @Input() data: any;
  @Input() group: string;
  @Input() config: any;
  properties: any;

  ngOnInit(): void {
    if (this.group === 'figuresDerivatives') {
      this.properties = Object.keys(this.data).map((p) => {
        return {
          label: p,
          config: {
            type: 'productLevel'
          }
        };
      });
    } else {
      this.properties = BasedataConfig(this.config)[this.group].filter(
        (c: any) => this.data[c.label]
      );
    }
  }
}
