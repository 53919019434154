import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ClickedConfig } from 'src/app/app.config';
import { MainSearchDataExtendedData } from '../search.config';
import { SearchService } from '../search.service';

@Component({
  selector: 'app-main-search-extended-search',
  templateUrl: './main-search-extended-search.component.html',
  styleUrls: ['./main-search-extended-search.component.scss']
})
export class MainSearchExtendedSearchComponent {
  @Input() data: MainSearchDataExtendedData;

  constructor(
    private router: Router,
    private searchService: SearchService
  ) {}

  onClickHandler(data: ClickedConfig): void {
    const value = data.value.label;
    const searchTerm = this.searchService.searchTerm.getValue();
    if (data.type === 'news') {
      this.router.navigateByUrl(`/news/${value}`);
      this.searchService.clearSearch();
    } else {
      this.router.navigateByUrl(`/markets/search/${searchTerm} ${value}`);
    }
  }
}
