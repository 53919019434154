import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getByProp'
})
export class GetByPropPipe implements PipeTransform {

  transform(data: any, type ?: string, base ?: number): any {
    return data.filter((d: any) => d[type] === base);
  }

}
