interface Tracking {
  scriptUrl: string;
  trackers: any[];
  routeTracking: any;
  requireCookieConsent: boolean;
  requireConsent: boolean;
}

const cookieSettings = {
  cookiesDisabled: (!navigator || !navigator.cookieEnabled),
  CONSENT_COOKIE_NAME: 'mtm_consent',
  CONSENT_REMOVED_COOKIE_NAME: 'mtm_consent_removed',
  cookieIsSecure: false,
  useSecureCookies: true,
  cookiePath: '',
  cookieDomain: '',
  cookieSameSite: 'Lax',
}

export const trackingConfig = (hostname: string, trackingId: number): Tracking => {
  const consent = hasConsent();
  return {
    scriptUrl: `//${hostname}/matomo.js`,
    trackers: [{
      trackerUrl: `//${hostname}/matomo.php`,
      siteId: trackingId
    }],
    routeTracking: {
      enable: true
    },
    requireCookieConsent: !consent,
    requireConsent: !consent
  }
};

export const isProd = (hostname: string): boolean => {
  return hostname === 'www.ivestor.de' || hostname === 'ivestor.de' ? true : false;
};

export const getWebsocketHost = (bool: boolean): string => {
  return bool ? 'www.ivestor.de' : 'service.ivestor.de';
}

export const getBackendHost = (hostname: string, websocket ? : boolean): string => {
  return isProd(hostname) ? getWebsocketHost(websocket) : 'backend.test.ivestor.de';
};

export const getTracking = (hostname: string, trackingId: number): Partial<Tracking> => {
  if (hostname === 'www.ivestor.de' || hostname === 'ivestor.de') {
    return trackingConfig('tracking.ivestor.de', trackingId);
  }
  return trackingConfig('tracking.test.ivestor.de', trackingId);
};

export const hasConsent = (): boolean | string => {
  const consentCookie = getCookie(cookieSettings.CONSENT_COOKIE_NAME);
  const removedCookie = getCookie(cookieSettings.CONSENT_REMOVED_COOKIE_NAME);
  if (!consentCookie && !removedCookie) {
    return true; // No cookies set, so opted in
  }
  if (removedCookie && consentCookie) {
    setCookie(cookieSettings.CONSENT_COOKIE_NAME, '', -129600000);
    return false;
  }
  return (consentCookie || consentCookie !== 0);
}

const getCookie = (cookieName: string): any => {
  const cookiePattern = new RegExp('(^|;)[ ]*' + cookieName + '=([^;]*)');
  const cookieMatch = cookiePattern.exec(document.cookie);
  return cookieMatch ? window.decodeURIComponent(cookieMatch[2]) : 0;
}

const setCookie = (cookieName: string, value: string, msToExpire: number) => {
  const expiryDate = new Date();
  expiryDate.setTime((new Date().getTime()) + msToExpire);
  document.cookie = cookieName + '=' + window.encodeURIComponent(value) +
    (msToExpire ? ';expires=' + expiryDate.toString() : '') +
    ';path=' + (cookieSettings.cookiePath || '/') +
    (cookieSettings.cookieDomain ? ';domain=' + cookieSettings.cookieDomain : '') +
    (cookieSettings.cookieIsSecure ? ';secure' : '') +
    ';SameSite=' + cookieSettings.cookieSameSite;
  if ((!msToExpire || msToExpire >= 0) && getCookie(cookieName) !== String(value)) {
    console.log('There was an error setting cookie `' + cookieName + '`. Please check domain and path.');
  }
}